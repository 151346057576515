// @ts-strict-ignore
import _ from 'lodash';
import tinycolor from 'tinycolor2';
import { MouseEvent } from 'react';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { getCapsuleFormula } from '@/datetime/dateTime.utilities';
import { ProcessTypeEnum } from '@/sdk/model/ThresholdMetricOutputV1';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import { METRIC_COLORS } from '@/toolSelection/investigate.constants';
import { sqItemsApi, sqMetricsApi } from '@/sdk';
import { swapAsset } from '@/search/searchResult.utilities.service';
import { formatNumber } from '@/utilities/numberHelper.utilities';
import { formatApiError, isPresentationWorkbookMode, validateGuid } from '@/utilities/utilities';
import { COLUMNS_AND_STATS, ITEM_TYPES, PropertyColumn, StatisticColumn } from '@/trendData/trendData.constants';
import { isCanceled } from '@/utilities/http.utilities';
import { AUTO_CLOSE_INTERVAL_LONG, errorToast, infoToast, successToast, warnToast } from '@/utilities/toast.utilities';
import { cancelGroup } from '@/requests/pendingRequests.utilities';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import i18next from 'i18next';
import {
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableBuilderMode,
} from '@/tableBuilder/tableBuilder.constants';
import {
  sqDurationStore,
  sqScorecardStore,
  sqTableBuilderStore,
  sqTrendStore,
  sqWorkbenchStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { priorityColors } from '@/services/systemConfiguration.utilities';
import {
  addTrendItem,
  alignMeasuredItemWithMetric,
  catchItemDataFailure,
  setItemSelected,
  setItemStatusNotRequired,
  setTrendSelectedRegion,
  toggleHideUnselectedItems,
} from '@/trendData/trend.actions';
import {
  canFetchData,
  computeCapsuleTable,
  computeScalar,
  computeTable,
  getDefaultName,
  getDependencies,
} from '@/utilities/formula.utilities';
import { getStatisticFragment } from '@/utilities/calculationRunner.utilities';
import { setView } from '@/worksheet/worksheet.actions';
import { ScorecardMetric } from '@/tableBuilder/scorecard.types';

export const DATA_CANCELLATION_GROUP = 'tableBuilder';
export const HOMOGENIZE_UNIT_FORMULA_EXCEPTIONS = [
  'is not compatible with',
  'non-linear, cannot convert',
  'Rows with different units are not allowed',
];

/**
 * Sets the mode of the table builder
 *
 * @param mode - The mode
 */
export function setMode(mode: TableBuilderMode) {
  flux.dispatch('TABLE_BUILDER_SET_MODE', { mode });
  setItemStatusNotRequired();
  exposedForTesting.fetchTable();
}

/**
 * Adds a column to the table that the user can use to input free-form text.
 */
export function addTextColumn() {
  flux.dispatch('TABLE_BUILDER_ADD_COLUMN', {
    type: TableBuilderColumnType.Text,
  });
}

/**
 * Adds an item property column to the table
 *
 * @param column - The property column to add
 * @param [isCapsuleProperty] - True if this is a capsule property, false if it is a property on an item.
 */
export function addPropertyColumn(
  column: { propertyName: string; style: string | undefined },
  isCapsuleProperty = false,
) {
  flux.dispatch('TABLE_BUILDER_ADD_COLUMN', {
    type: isCapsuleProperty ? TableBuilderColumnType.CapsuleProperty : TableBuilderColumnType.Property,
    style: column.style,
    propertyName: column.propertyName,
  });
  exposedForTesting.fetchTable();
}

export function refreshTable() {
  exposedForTesting.fetchTable(true);
}

/**
 * Removes the specified column from the table
 *
 * @param key - The key that identifies the column
 */
export function removeColumn(key: string) {
  if (_.has(_.find(sqTableBuilderStore.columns, { key }), 'filter')) {
    setColumnFilter(key, undefined);
  }
  flux.dispatch('TABLE_BUILDER_REMOVE_COLUMN', { key });
}

/**
 * Adds or removes a particular column into the table builder store.
 *
 * @param column - The column being toggled. One of COLUMNS_AND_STATS
 * @param [signalId] - The series if it is a statistic column for condition table
 */
export function toggleColumn(column: PropertyColumn | StatisticColumn, signalId: string = null) {
  if (sqTableBuilderStore.isColumnEnabled(column, signalId)) {
    exposedForTesting.removeColumn(sqTableBuilderStore.getColumnKey(column, signalId));
  } else {
    const uom = COLUMNS_AND_STATS.valueUnitOfMeasure;
    const disableUnitHomogenization =
      column.key === uom.key && !_.isUndefined(sqTableBuilderStore.assetId) && sqTableBuilderStore.isHomogenizeUnits;
    if (disableUnitHomogenization) {
      infoToast({
        messageKey: 'TABLE_BUILDER.UNIT_HOMOGENIZATION_DISABLED',
      });
      exposedForTesting.setHomogenizeUnits(false, false);
    }

    flux.dispatch('TABLE_BUILDER_ADD_COLUMN', { column, signalId });
    exposedForTesting.fetchTable();
  }
}

/**
 * Moves the specified column to a new position
 *
 * @param key - The key that identifies the column.
 * @param newKey - The key that identifies the column that will be the new position
 */
export function moveColumn(key: string, newKey: string) {
  flux.dispatch('TABLE_BUILDER_MOVE_COLUMN', { key, newKey });
}

export function isTableColumnEnabled(column: PropertyColumn | StatisticColumn, signalId: string = null) {
  return sqTableBuilderStore.isColumnEnabled(column, signalId);
}

/**
 * Sets the background color for a table column (header excluded).
 *
 * @param key - The key that identifies the column.
 * @param color - Background color for the column
 */
export function setColumnBackground(key: string, color: string) {
  flux.dispatch('TABLE_BUILDER_SET_COLUMN_BACKGROUND', { key, color });
}

/**
 * Sets the text alignment for a table column (header excluded).
 *
 * @param key - The key that identifies the column.
 * @param align - CSS text-align value
 */
export function setColumnTextAlign(key: string, align: string) {
  flux.dispatch('TABLE_BUILDER_SET_COLUMN_TEXT_ALIGN', { key, align });
}

/**
 * Sets the text color for a table column (header excluded).
 *
 * @param key - The key that identifies the column.
 * @param color - Text color
 */
export function setColumnTextColor(key: string, color: string) {
  flux.dispatch('TABLE_BUILDER_SET_COLUMN_TEXT_COLOR', { key, color });
}

/**
 * Sets the text style for a table column (header excluded).
 *
 * @param key - The key that identifies the column.
 * @param style - zero or more text style attributes
 */
export function setColumnTextStyle(key: string, style: string[]) {
  flux.dispatch('TABLE_BUILDER_SET_COLUMN_TEXT_STYLE', { key, style });
}

/**
 * Sets the background color for a table header.
 *
 * @param key - The key that identifies the column.
 * @param color - Background color for the header
 */
export function setHeaderBackground(key: string, color: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADER_BACKGROUND', { key, color });
}

/**
 * Sets the text alignment for a table header.
 *
 * @param key - The key that identifies the column.
 * @param align - CSS text-align value
 */
export function setHeaderTextAlign(key: string, align: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT_ALIGN', { key, align });
}

/**
 * Sets the text color for a table header.
 *
 * @param key - The key that identifies the column.
 * @param color - Text color
 */
export function setHeaderTextColor(key: string, color: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT_COLOR', { key, color });
}

/**
 * Sets the text style for a table header.
 *
 * @param key - The key that identifies the column.
 * @param style - zero or more text style attributes
 */
export function setHeaderTextStyle(key: string, style: string[]) {
  flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT_STYLE', { key, style });
}

/**
 * Applies the formatting of the specified column to all columns (headers excluded)
 * @param key - The key that identifies the column.
 */
export function setStyleToAllColumns(key: string) {
  flux.dispatch('TABLE_BUILDER_SET_STYLE_TO_ALL_COLUMNS', { key });
}

/**
 * Applies the formatting of the specified column to all headers
 * @param key - The key that identifies the column.
 */
export function setStyleToAllHeaders(key: string) {
  flux.dispatch('TABLE_BUILDER_SET_STYLE_TO_ALL_HEADERS', { key });
}

/**
 * Applies the formatting of the specified column&header to all columns and headers
 * @param key - The key that identifies the column.
 */
export function setStyleToAllHeadersAndColumns(key: string) {
  flux.dispatch('TABLE_BUILDER_SET_STYLE_TO_ALL_HEADERS_AND_COLUMNS', {
    key,
  });
}

/**
 * Copies the formatting of the specified column&header.
 * @param key - The key that identifies the column.
 */
export function copyStyle(key: string) {
  flux.dispatch('TABLE_BUILDER_COPY_STYLE', { key });
}

/**
 * Applies the copied formatting to the specified column header
 * @param key - The key that identifies the column.
 */
export function pasteStyleOnHeader(key: string) {
  flux.dispatch('TABLE_BUILDER_PASTE_STYLE_ON_HEADER', { key });
}

/**
 * Applies the copied formatting to the specified column (header excluded)
 * @param key - The key that identifies the column.
 */
export function pasteStyleOnColumn(key: string) {
  flux.dispatch('TABLE_BUILDER_PASTE_STYLE_ON_COLUMN', { key });
}

/**
 * Applies the copied formatting to the specified column&header
 * @param key - The key that identifies the column.
 */
export function pasteStyleOnHeaderAndColumn(key: string) {
  flux.dispatch('TABLE_BUILDER_PASTE_STYLE_ON_HEADER_AND_COLUMN', { key });
}

/**
 * Filter a column in the Simple Table
 *
 * @param key - The key that identifies the column.
 * @param filter - filter to apply to the column
 */
export function setColumnFilter(key: string, filter: TableColumnFilter) {
  flux.dispatch('TABLE_BUILDER_SET_COLUMN_FILTER', { key, filter });
  exposedForTesting.fetchTable();
}

export function sortByColumn(key: string, direction: string) {
  flux.dispatch('TABLE_BUILDER_SORT_BY_COLUMN', { key, direction });
  exposedForTesting.fetchTable();
}

/**
 * Sets the text for a scorecard column cell or header.
 *
 * @param key - The key that identifies the column.
 * @param text - Text for the cell
 * @param [cellKey] - The identifier for the cell. If not specified the column header text will be set.
 */
export function setCellText(key: string, text: string, cellKey?: string) {
  flux.dispatch('TABLE_BUILDER_SET_CELL_TEXT', { key, text, cellKey });
}

/**
 * Sets the text for a table builder column header.
 *
 * @param columnKey - The key that identifies the column.
 * @param text - Text for the header
 */
export function setHeaderText(columnKey: string, text: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT', { columnKey, text });
}

/**
 * Sets the header override flag for a column. Other column overrides will be disabled.
 *
 * @param columnKey - The key that identifies the column.
 */
export function setHeaderOverridden(columnKey: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADER_OVERRIDE', { columnKey });
}

/**
 * Sets the header type for either scorecard columns that display the metric values or the name column for simple
 * tables.
 *
 * @param type - The type of header to display
 */
export function setHeadersType(type: TableBuilderHeaderType) {
  flux.dispatch('TABLE_BUILDER_SET_HEADERS_TYPE', { type });
  if (type === TableBuilderHeaderType.CapsuleProperty) {
    exposedForTesting.fetchTable();
  }
}

/**
 * Sets the date format used for headers of metric value columns/name column when the type is one of the date types.
 *
 * @param format - A string that can be passed to moment's format()
 */
export function setHeadersFormat(format: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADERS_FORMAT', { format });
}

/**
 * Sets the name of the capsule property used for headers of metric value columns when the type is CapsuleProperty.
 *
 * @param property - The capsule property name
 */
export function setHeadersProperty(property: string) {
  flux.dispatch('TABLE_BUILDER_SET_HEADERS_PROPERTY', { property });
  exposedForTesting.fetchTable();
}

export function setIsTransposed(isTransposed: boolean) {
  flux.dispatch('TABLE_BUILDER_SET_IS_TRANSPOSED', { isTransposed });
}

/**
 * Sets or clears the asset id so the table can be run across all the child assets of the asset.
 *
 * @param assetId - The root asset to run the formula across or undefined to clear.
 */
export function setAssetId(assetId: string | undefined) {
  flux.dispatch('TABLE_BUILDER_SET_ASSET_ID', { assetId });
  if (assetId) {
    flux.dispatch('TABLE_BUILDER_ADD_COLUMN', { column: { key: 'asset' } });
  } else {
    flux.dispatch('TABLE_BUILDER_REMOVE_COLUMN', { key: 'asset' });
  }

  exposedForTesting.fetchTable();
}

/**
 * Sets the homogenizeUnits attribute and removes the UOM column when the units are homogenized. In this case, we do
 * not want to show the UOM column. The unit of some values might be different than the unit of the item
 * @param homogenizeUnits - The homogenize units value
 * @param fetchTable - When true, it fetches the table
 * @return void if only homogenizeUnits is set. When fetch table is needed it returns a promise resolves when the
 * table has been been fetched
 */
export function setHomogenizeUnits(homogenizeUnits: boolean, fetchTable = false): void | Promise<void | any[]> {
  flux.dispatch('TABLE_BUILDER_SET_HOMOGENIZE_UNITS', { homogenizeUnits });
  if (
    homogenizeUnits &&
    sqTableBuilderStore.isSimpleMode() &&
    sqTableBuilderStore.isColumnEnabled(COLUMNS_AND_STATS.valueUnitOfMeasure)
  ) {
    const key = COLUMNS_AND_STATS.valueUnitOfMeasure.key;
    flux.dispatch('TABLE_BUILDER_REMOVE_COLUMN', { key });
    infoToast({
      messageKey: 'TABLE_BUILDER.UNIT_COLUMN_REMOVED',
    });
  }

  if (fetchTable) {
    return exposedForTesting.fetchTable();
  }
}

/**
 * Changes to the specified the asset id only if a current asset is already set and the new one is different.
 *
 * @param assetId - The asset to change to
 */
export function changeAssetId(assetId: string) {
  if (sqTableBuilderStore.assetId && sqTableBuilderStore.assetId !== assetId) {
    exposedForTesting.setAssetId(assetId);
  }
}

export function setIsMigrating(isMigrating: boolean) {
  flux.dispatch('TABLE_BUILDER_SET_IS_MIGRATING', { isMigrating });
}

export function setIsTableStriped(isTableStriped: boolean) {
  flux.dispatch('TABLE_BUILDER_SET_IS_TABLE_STRIPED', { isTableStriped });
}

export function setShowChartView(showChart: boolean) {
  flux.dispatch('TABLE_BUILDER_SET_CHART_VIEW', { enabled: showChart });
}

/**
 * True/false toggle gives user a choice between "Signal colors" and "Seeq colors" for the Highcharts chart
 * in the Tables & Charts section of Workbench Analysis. When true, the colors selected in the Details
 * pane are used, unless something overrides the choice (such as being in Transposed mode).
 * @param useSignalColorsInChart - When true, uses the signal colors defined in the Details pane below the chart
 * @return void
 */
export function setUseSignalColorsInChart(useSignalColorsInChart: boolean) {
  flux.dispatch('TABLE_BUILDER_SET_USE_SIGNAL_COLORS_IN_CHART', { useSignalColorsInChart });
}

export function setChartViewSettings(settings: any) {
  flux.dispatch('TABLE_BUILDER_SET_CHART_VIEW_SETTINGS', {
    settings: { ...settings },
  });
}

/**
 * Remove the v1 metric.
 *
 * @param {string} metricId - The id of the metric
 */
export function removeOldMetric(metricId) {
  flux.dispatch('SCORECARD_REMOVE_METRIC', { metricId });
}

/**
 * Fetches and dispatches the table data.
 *
 * @return {Promise} A promise that resolves when the table has been been fetched
 */
export function fetchTable(forceRefresh = false): Promise<void | any[]> {
  if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TABLE) {
    return Promise.resolve();
  }
  cancelGroup(DATA_CANCELLATION_GROUP, false);
  if (sqScorecardStore.metrics.length) {
    return exposedForTesting.fetchOldMetrics();
  } else if (sqTableBuilderStore.isSimpleMode()) {
    return exposedForTesting.fetchSimpleTableData(forceRefresh);
  } else {
    return exposedForTesting.fetchConditionTableData(forceRefresh);
  }
}

/**
 * Fetches and dispatches the simple table data.
 *
 * @return A promise that resolves when the table has been been fetched
 */
export function fetchSimpleTableData(forceRefresh = false): Promise<void | any[]> {
  const { formula, reduceFormula, parameters, root, columnPositions } =
    sqTableBuilderStore.getSimpleTableFetchParams(forceRefresh);
  if (_.isEmpty(formula)) {
    return Promise.resolve();
  }

  const itemIds = _.chain(parameters).values().filter(validateGuid).value();
  _.forEach(itemIds, (id) => {
    flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE);
  });

  return computeTable({
    formula,
    parameters,
    reduceFormula,
    root,
    cancellationGroup: DATA_CANCELLATION_GROUP,
    usePost: true, // Formula can be very long
  })
    .then((results) => {
      flux.dispatch(
        'TABLE_BUILDER_PUSH_SIMPLE_DATA',
        { data: results.data, headers: results.headers, columnPositions },
        PUSH_IGNORE,
      );
      _.forEach(itemIds, (id) => {
        flux.dispatch(
          'TREND_SET_DATA_STATUS_PRESENT',
          {
            id,
            warningCount: results.warningCount,
            warningLogs: results.warningLogs,
            timingInformation: results.timingInformation,
            meterInformation: results.meterInformation,
            isSharedRequest: true,
          },
          PUSH_IGNORE,
        );
      });
    })
    .then(() => exposedForTesting.fetchSimpleTableDistinctStringValues())
    .catch((e) =>
      exposedForTesting.catchTableBuilderDataFailure(e, TableBuilderMode.Simple, sqTableBuilderStore.getTableItems()),
    );
}

/**
 * Fetch the distinct string values for each string-valued column in the Simple Table.
 * Noops in presentation mode since the filters can't be changed.
 */
export function fetchSimpleTableDistinctStringValues(): Promise<void> {
  if (isPresentationWorkbookMode()) {
    return Promise.resolve();
  }

  const { fetchParamsList, columnKeysNamesList } = sqTableBuilderStore.getSimpleTableStringColumnsFetchParams();
  return Promise.all(
    _.map(fetchParamsList, (tableFetchParam) =>
      computeTable({
        formula: tableFetchParam.formula,
        parameters: tableFetchParam.parameters,
        reduceFormula: tableFetchParam.reduceFormula,
        root: tableFetchParam.root,
        cancellationGroup: DATA_CANCELLATION_GROUP,
        usePost: true, // Formula can be very long
      }),
    ),
  ).then((stringValueTables) => {
    flux.dispatch('TABLE_BUILDER_SET_SIMPLE_DISTINCT_STRING_VALUES', {
      stringValueTables,
      columnKeysNamesList,
    });
  });
}

/**
 * Fetches and dispatches the condition table data.
 *
 * @return A promise that resolves when the table has been fetched
 */
export function fetchConditionTableData(forceRefresh = false): Promise<void | any[]> {
  const {
    ids: itemIds,
    assetId,
    propertyColumns,
    statColumns,
    customPropertyName,
    reduceFormula,
    sortParams,
    buildAdditionalFormula,
    itemColumnsMap,
    buildConditionFormula,
    buildStatFormula,
  } = sqTableBuilderStore.getConditionTableFetchParams();

  if (_.isEmpty(itemIds)) {
    return Promise.resolve();
  }

  _.forEach(itemIds, (id) => {
    flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE);
  });

  return computeCapsuleTable({
    columns: { propertyColumns, statColumns },
    range: { start: sqDurationStore.displayRange.start.valueOf(), end: sqDurationStore.displayRange.end.valueOf() },
    itemIds,
    buildConditionFormula,
    sortParams,
    root: assetId,
    reduceFormula,
    buildAdditionalFormula,
    buildStatFormula,
    cacheTableFormula: sqTableBuilderStore.getCacheTableFormula(forceRefresh),
    offset: 0,
    limit: 10000,
    cancellationGroup: DATA_CANCELLATION_GROUP,
  })
    .then(({ data: { headers, table, timingInformation, meterInformation, warningCount, warningLogs } }) => {
      flux.dispatch(
        'TABLE_BUILDER_PUSH_CONDITION_DATA',
        { headers, table, itemColumnsMap, customPropertyName },
        PUSH_IGNORE,
      );
      _.forEach(itemIds, (id) => {
        flux.dispatch(
          'TREND_SET_DATA_STATUS_PRESENT',
          {
            id,
            warningCount,
            warningLogs,
            timingInformation,
            meterInformation,
            isSharedRequest: true,
          },
          PUSH_IGNORE,
        );
      });
    })
    .catch((e) =>
      exposedForTesting.catchTableBuilderDataFailure(
        e,
        TableBuilderMode.Condition,
        sqTableBuilderStore.getTableItems(),
      ),
    );
}

/**
 * Performs all necessary steps to execute all v1 metrics using the current display range.
 *
 * @return Promise that resolves when the cell is computed
 */
export function fetchOldMetrics(): Promise<void> {
  return (
    _.chain(sqScorecardStore.metrics)
      .map((metric: ScorecardMetric) => {
        const statFragment = getStatisticFragment(metric.stat);
        const formula = `$series.aggregate(${statFragment}, ${getCapsuleFormula(sqDurationStore.displayRange)})`;

        return computeScalar({ formula, parameters: { series: metric.itemId } }).then((result) => {
          const payload = _.assign(
            {
              metricId: metric.metricId,
              valueResult: `${formatNumber(result.value)} ${result.uom}`,
            },
            exposedForTesting.computeColorForOldMetric(metric, result.value),
          );

          flux.dispatch('SCORECARD_VALUE_RESULT', payload);
        });
      })
      .thru((promises) => Promise.all(promises))
      .value()
      // Noop at the end so we have a void return type
      .then(() => {})
  );
}

/**
 * Compute the color for a given value. This finds the maximum threshold value that is less or equal to the value,
 * and returns that. It also computes the contrasting color for the foreground.
 *
 * @param {Object} metric - The metric object
 * @param {Object} metric.thresholds - Array of threshold objects
 * @param {Number} value - The value to choose colors for
 * @returns {{backgroundColor: string, foregroundColor: string}} - Map of background and foreground colors
 */
export function computeColorForOldMetric(metric, value) {
  const color = (
    _.chain(metric.thresholds)
      .filter(function (t: any) {
        return !_.isUndefined(t.isMinimum) || t.threshold <= value;
      })
      .head() as any
  )
    .get('color', '#ddd')
    .value();
  return {
    backgroundColor: color,
    foregroundColor: tinycolor(color).isDark() ? '#fff' : '#000',
  };
}

/**
 * Displays a metric on the trend with a specific time region of the chart highlighted. It also takes care of
 * swapping to the new asset if the table is going across assets and the row that the user is clicking on is from a
 * different asset than the one currently being shown.
 *
 * @param metricId - The metric identifier
 * @param itemId - The id of the actual item in the row. This can be different from the metricId when swapping
 * @param start - The start time of the window to highlight
 * @param end - The end time of the window to highlight
 * @param event - The click event
 */
export function displayMetricOnTrend(
  metricId: string,
  itemId: string,
  start: number,
  end: number,
  event?: MouseEvent,
): Promise<any> {
  if ((event?.view as any)?.getSelection().toString().length > 0) {
    return Promise.resolve(); // noop if the user is selecting the value
  }

  let metric = _.find(
    getAllItems({
      itemTypes: [ITEM_TYPES.METRIC],
    }),
    { id: metricId },
  );
  const isItemPresent = _.some(getAllItems({}), { id: itemId });
  let promise;
  if (!sqTableBuilderStore.assetId || isItemPresent) {
    promise = Promise.resolve();
  } else {
    // User clicked on a metric whose item is not in the details pane, so swap to its parent
    promise = getDependencies({ id: itemId }).then(({ assets }) => {
      if (assets.length && assets[0].pathComponentIds.length) {
        swapAsset({ id: _.last(assets[0].pathComponentIds) }).then(() => {
          // Actual item clicked should now be in the details pane
          metric = _.find(getAllItems({ itemTypes: [ITEM_TYPES.METRIC] }), { id: itemId });
        });
      }
    });
  }

  return promise.then(() => {
    setView(WORKSHEET_VIEW.TREND);

    const isSimpleMetric = _.get(metric, 'definition.processType') === ProcessTypeEnum.Simple;
    const isBatchMetric = _.get(metric, 'definition.processType') === ProcessTypeEnum.Condition;
    const boundingCondition = _.get(metric, 'definition.boundingCondition', {});
    if (isBatchMetric && boundingCondition.id) {
      addTrendItem(boundingCondition).then((bc) => setItemSelected(bc, true));
    }

    _.forEach(getAllItems({}), (item) => setItemSelected(item, item.id === metric.id));

    if (isSimpleMetric) {
      addTrendItem(metric.definition.measuredItem).then(() => alignMeasuredItemWithMetric(metric));
    }

    if (sqDurationStore.displayRange.start.valueOf() !== start || sqDurationStore.displayRange.end.valueOf() !== end) {
      setTrendSelectedRegion(start, end);
    }

    if (!sqTrendStore.hideUnselectedItems) {
      toggleHideUnselectedItems();
    }
  });
}

/**
 * Migrates old scorecard to be backend threshold metric items.
 */
export function migrate() {
  exposedForTesting.setIsMigrating(true);
  exposedForTesting.setMode(TableBuilderMode.Simple);
  exposedForTesting.setHeadersType(TableBuilderHeaderType.None);
  exposedForTesting.removeColumn(COLUMNS_AND_STATS['statistics.average'].key);
  exposedForTesting.toggleColumn(COLUMNS_AND_STATS.metricValue);
  _.chain(sqScorecardStore.metrics)
    .map((metric: any) =>
      exposedForTesting
        .getName(metric)
        .then((name) =>
          sqMetricsApi.createThresholdMetric({
            name,
            measuredItem: metric.itemId,
            aggregationFunction: getStatisticFragment(metric.stat),
            thresholds: exposedForTesting.getThresholds(metric),
          }),
        )
        .then(({ data: item }) => {
          exposedForTesting.removeOldMetric(metric.metricId);
          return item;
        })
        .catch((error) => {
          errorToast({ httpResponseOrError: error });
        }),
    )
    .thru((promises) => Promise.all(promises))
    .value()
    // Important that they are added in order to preserve the sort
    .then((items) =>
      Promise.all(
        _.map(items, (item) => {
          const promise = addTrendItem(item);
          setItemSelected(item, true);
          return promise;
        }),
      ),
    )
    .finally(() => {
      exposedForTesting.setIsMigrating(false);
      successToast(
        {
          messageKey: 'TABLE_BUILDER.MIGRATION_SUCCESS',
        },
        { autoClose: AUTO_CLOSE_INTERVAL_LONG },
      );
    });
}

/**
 * Returns a name for the metric. Specifically handles the case of metric that had an empty name. It is not
 * guaranteed to be unique. Instead, since the backend does not enforce uniqueness, it assumes the user will figure
 * out the best way to disambiguate duplicate names when they edit it.
 *
 * @param {Object} metric - The metric
 * @return {Promise<String>} - Promise that resolves with a name for the metric
 */
export function getName(metric) {
  return Promise.resolve(_.trim(metric.name))
    .then((name) => {
      if (_.isEmpty(name)) {
        return sqItemsApi.getItemAndAllProperties({ id: metric.itemId }).then(({ data: item }) => {
          const statTitle = i18next.t(
            _.get(_.find(SAMPLE_FROM_SCALARS.VALUE_METHODS, ['key', _.get(metric.stat, 'key')]), 'title'),
          );

          return `${statTitle} ${item.name}`;
        });
      }

      return name;
    })
    .then((name) => getDefaultName(name, sqWorkbenchStore.stateParams.workbookId))
    .then((defaultName) => {
      // getDefaultName name will add a suffix, but if that suffix is 1 the name is unique so we don't need the
      // number
      if (_.endsWith(defaultName, ' 1')) {
        return defaultName.substr(0, defaultName.length - 2);
      }

      return defaultName;
    });
}

/**
 * Gets the thresholds for a metric, mapped to the new priority levels. Makes no assumptions that the colors are
 * the same, but instead makes the assumption that the user ordered their metrics with the same priority order as
 * the order presented by METRIC_COLORS. It has the following known limitations:
 *  - It can assign the same level to two different thresholds if there is no corresponding new priority. This
 *  could happen, for example, if user used both the green and blue as thresholds, since blue was removed in the
 *  new scorecard.
 *  - If the thresholds have the colors in a random order, that order will not be preserved since the order cannot
 *  be changed for the new priorities.
 *  - If the user defined more thresholds than the number of new priorities then some of the old thresholds will
 *  be lost.
 *
 * @param {Object} metric - The metric
 * @return {String[]} Array of thresholds in the format of priorityLevel=value
 */
export function getThresholds(metric): string[] {
  const highPriorities = _.filter(priorityColors(), (priority) => priority.level > 0);
  const lowercaseMetricColors = _.map(METRIC_COLORS, _.toLower);
  const priorityConversionMap = _.chain(lowercaseMetricColors)
    .initial() // discard the last color (white) since neutral is not included in priorities
    .reverse() // reverse it so that the indices correspond with the priority levels
    .transform((result, color, i) => {
      // Use the index to find the corresponding priority. Green's index is zero and with this algorithm that
      // means it ends up as the neutral priority, but that is ok since R21 moved green to a neutral color. The
      // rest of the colors will then map to their new corresponding priority level.
      result[color] = _.get(_.find(highPriorities, { level: i }), 'level', _.last(highPriorities).level);
    }, {} as Record<string, number>)
    .value();

  // Needed because some old scorecards somehow have some of their colors as strings instead of hex codes
  const thresholds = _.map(metric.thresholds, (threshold: any) => {
    // yellow yields a different hex code
    const colorObj = threshold.color === 'yellow' ? tinycolor(METRIC_COLORS[1]) : tinycolor(threshold.color);
    const color = colorObj.isValid() ? colorObj.toHexString() : threshold.color;
    return { ...threshold, color };
  });

  return _.chain(thresholds)
    .transform((result, threshold: any, i) => {
      // Last one will not have a threshold value
      if (i === thresholds.length - 1) {
        return;
      }

      const currentColorIndex = _.indexOf(lowercaseMetricColors, threshold.color);
      const nextColorIndex = _.indexOf(lowercaseMetricColors, thresholds[i + 1].color);
      // Can tell if the priorities are high or low since the old METRIC_COLORS went from high to low
      const isHigh = currentColorIndex <= nextColorIndex;
      const color = lowercaseMetricColors[isHigh ? currentColorIndex : nextColorIndex];
      // White thresholds that were not used as neutral will not be in the map
      if (_.has(priorityConversionMap, color)) {
        const level = priorityConversionMap[color] * (isHigh ? 1 : -1);
        result.push([level, threshold.threshold]);
      }
    }, [])
    .uniqBy(_.head)
    .map(([level, threshold]) => `${level}=${threshold}`)
    .value();
}

/**
 * Error handler for when fetching the table data fails. It checks to see if changing the homogenize units setting
 * would help. Otherwise, it tries to determine which items that were used in the table builder formula are
 * causing the problem by fetching each one individually. This is expensive, but there's no other good way to
 * figure it out.
 *
 * @param error - The error that arose from fetching the data
 * @param mode - The current table builder mode
 * @param items - The items that were used to generate the table builder formula
 */
export function catchTableBuilderDataFailure(error: any, mode: TableBuilderMode, items: any[]) {
  let apiMessage;
  let fetchFailedMessage = formatApiError(error).replace(/\((GET|POST) .*?\)/, '');
  const isHomogenizeError = HOMOGENIZE_UNIT_FORMULA_EXCEPTIONS.some((err) => fetchFailedMessage.includes(err));
  const isAssetError = fetchFailedMessage.includes('asset');
  const shouldFallbackToUnitless = !!sqTableBuilderStore.assetId && isHomogenizeError;

  if (shouldFallbackToUnitless) {
    warnToast({
      messageKey: 'TABLE_BUILDER.INCOMPATIBLE_UNITS_ACROSS_ASSETS',
      messageParams: { message: fetchFailedMessage },
    });
    flux.dispatch('TABLE_BUILDER_ADD_COLUMN', {
      column: COLUMNS_AND_STATS.valueUnitOfMeasure,
    });
    return exposedForTesting.setHomogenizeUnits(false, true);
  } else {
    let checkFailureForEachItem = false;
    if (isCanceled(error)) {
      fetchFailedMessage = undefined; // Request will be retried so don't flash an error message
    } else {
      if (_.isEmpty(fetchFailedMessage)) {
        fetchFailedMessage = i18next.t('LOGIN_PANEL.FRONTEND_ERROR');
      } else if (fetchFailedMessage.includes('must all be from a single asset')) {
        fetchFailedMessage += `\n\n${i18next.t('TABLE_BUILDER.REMOVE_ITEMS_SAME_ASSET')}`;
      } else if (!isAssetError && !isHomogenizeError) {
        // Sometimes we hit errors due to unit mismatches in the table data, so we provide the API error message in
        // addition to our fetch error message to give more information to the user
        apiMessage = fetchFailedMessage;
        fetchFailedMessage = i18next.t('TABLE_BUILDER.FETCH_ERROR');
        checkFailureForEachItem = true;
      }
    }

    if (checkFailureForEachItem && !isPresentationWorkbookMode()) {
      _.forEach(items, (item) => {
        canFetchData(item, sqDurationStore.displayRange, DATA_CANCELLATION_GROUP)
          .then(() => {
            flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', { id: item.id });
          })
          .catch((e) => catchItemDataFailure(item.id, DATA_CANCELLATION_GROUP, e));
      });
    } else {
      _.forEach(items, (item) => catchItemDataFailure(item.id, DATA_CANCELLATION_GROUP, error));
    }

    flux.dispatch('TABLE_BUILDER_SET_FETCH_FAILED_MESSAGE', {
      fetchFailedMessage,
      mode,
      apiMessage,
    });
  }
}

export const exposedForTesting = {
  setChartViewSettings,
  setShowChartView,
  fetchTable,
  setHomogenizeUnits,
  setAssetId,
  fetchOldMetrics,
  fetchConditionTableData,
  fetchSimpleTableData,
  catchTableBuilderDataFailure,
  fetchSimpleTableDistinctStringValues,
  setIsMigrating,
  setMode,
  computeColorForOldMetric,
  removeOldMetric,
  getThresholds,
  setHeadersType,
  getName,
  removeColumn,
  toggleColumn,
  addTextColumn,
  moveColumn,
  setColumnBackground,
  setColumnTextAlign,
  setColumnTextColor,
  setColumnTextStyle,
  setHeaderBackground,
  setHeaderTextAlign,
  setHeaderTextColor,
  setHeaderTextStyle,
  setStyleToAllColumns,
  setStyleToAllHeaders,
  setStyleToAllHeadersAndColumns,
  copyStyle,
  pasteStyleOnHeader,
  pasteStyleOnColumn,
  pasteStyleOnHeaderAndColumn,
  setCellText,
  setHeaderText,
  setHeadersFormat,
  setHeadersProperty,
  setIsTableStriped,
  setColumnFilter,
};
