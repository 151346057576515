import { IHeaderParams } from '@ag-grid-community/core';
import React from 'react';
import { TableBuilderTextHeaderSimple } from '@/tableBuilder/tableComponents/TableBuilderTextHeaderSimple';
import { TableBuilderTextHeaderSimpleProps } from '@/tableBuilder/tableBuilder.types';
import _ from 'lodash';
import { useUpdateHeaderBackground } from '@/tableBuilder/ag/hooks/useUpdateHeaderBackground.hook';

export interface TableHeaderSimpleProps extends IHeaderParams, TableBuilderTextHeaderSimpleProps {}

export const TableHeaderSimple: React.FunctionComponent<TableHeaderSimpleProps> = (props) => {
  const { eGridHeader, simpleColumn } = props;

  useUpdateHeaderBackground(simpleColumn.headerBackgroundColor, eGridHeader);

  return <TableBuilderTextHeaderSimple {...props} isAgGrid={true} />;
};
