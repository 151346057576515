import _ from 'lodash';
import classNames from 'classnames';
import React, { MouseEvent } from 'react';
import { TFunction } from 'react-i18next';
import { getCellValue } from '@/tableBuilder/tableComponents/tableBuilderCell.utilities';

interface DataCellIF {
  onClick?: (event: MouseEvent<any, any>) => void;
  textValue: string;
  showUnit?: boolean;
  units?: string;
  columnIndex: number;
  style: Object;
  extraClassNames?: string;
  key?: number;
  t: TFunction;
  isAgGrid?: boolean;
}

export const TableBuilderDataCell: React.FunctionComponent<DataCellIF> = (props) => {
  const { onClick = _.noop, textValue, showUnit = false, units, style, extraClassNames, t, isAgGrid = false } = props;

  const alignmentClasses = isAgGrid ? 'flexColumnContainer flexAlignCenter' : 'forceVerticalAlignMiddle';
  const className = classNames('nowrap', alignmentClasses, extraClassNames);
  const dataTestId = 'tableBuilderDataCell';

  return isAgGrid ? (
    <div className={className} data-testid={dataTestId} style={style} onClick={onClick}>
      <div>{getCellValue(textValue, showUnit, units)}</div>
    </div>
  ) : (
    <td className={className} data-testid={dataTestId} style={style} onClick={onClick}>
      {getCellValue(textValue, showUnit, units)}
    </td>
  );
};
