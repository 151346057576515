import React from 'react';
import { TableHeaderConditionProps } from '@/tableBuilder/tableBuilder.types';
import { TableBuilderTextHeader } from '@/tableBuilder/tableComponents/TableBuilderTextHeader.atom';
import _ from 'lodash';
import { useUpdateHeaderBackground } from '@/tableBuilder/ag/hooks/useUpdateHeaderBackground.hook';

export const TableHeaderCondition: React.FunctionComponent<TableHeaderConditionProps> = (props) => {
  const { eGridHeader, headerBackgroundColor } = props;

  useUpdateHeaderBackground(headerBackgroundColor, eGridHeader);

  return <TableBuilderTextHeader {...props} isAgGrid={true} />;
};
