import React from 'react';
import _ from 'lodash';
import { NewLineToBr } from '@/core/NewLineToBr.atom';
import { ENUM_REGEX } from '@/trendData/trendData.constants';
import { t } from 'i18next';
import { NULL_PLACEHOLDER } from '@/tableBuilder/tableBuilder.constants';

export function getCellValue(
  textValue: string | undefined,
  showUnit: boolean,
  units: string | undefined,
  specialCaseNewlines = true,
) {
  if (_.isNil(textValue)) {
    return '';
  } else if (_.includes(textValue, '\n') && specialCaseNewlines) {
    return <NewLineToBr lineToBreak={textValue} />;
  } else if (_.isBoolean(textValue)) {
    return t(`ADMIN.CONFIGURATION.${textValue ? 'LABEL_VALUE_TRUE' : 'LABEL_VALUE_FALSE'}`);
  }

  const match = textValue?.toString().match(ENUM_REGEX);
  if (match) {
    return match[2];
  }

  if (showUnit && !_.isEmpty(units) && textValue !== NULL_PLACEHOLDER) {
    return `${textValue} ${units}`;
  } else {
    return textValue;
  }
}
