import React from 'react';
import { DataHeaderProps } from '@/tableBuilder/tableBuilder.types';
import classNames from 'classnames';

const className = 'text-bolder text-center forceVerticalAlignMiddle specDataColumnHeader';
const testId = 'tableBuilderDataHeader';

export const TableBuilderDataHeader: React.FunctionComponent<DataHeaderProps> = ({ headerValue, isAgrid }) => {
  if (isAgrid) {
    return (
      <div className={className} data-testid={testId}>
        {headerValue}
      </div>
    );
  }

  return (
    <td className={classNames(className, 'nowrap')} data-testid={testId}>
      {headerValue}
    </td>
  );
};
