import React, { useRef } from 'react';
import _ from 'lodash';
import { AgGridReact } from '@ag-grid-community/react';
import {
  CONTENT_DEFAULT_COL_DEF,
  DEFAULT_COL_DEF,
  SIMPLE_TABLE_STRIPED_FIELD,
  SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN,
  TableBuilderColumnType,
} from '@/tableBuilder/tableBuilder.constants';
import { TableBuilderSimpleAgGridProps } from '@/tableBuilder/tableBuilder.types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getStripedColor } from '@/utilities/tableBuilderHelper.utilities';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import { getCellValue } from '@/tableBuilder/tableComponents/tableBuilderCell.utilities';
import {
  addScreenshotSetup,
  autoSizeColumns,
  calculateWidth,
  createSimpleColDefs,
  getSimpleTableHeaderComponentParams,
  sizeGridToTableCell,
} from '@/tableBuilder/tableBuilderAgGrid.utilities';
import classNames from 'classnames';
import { isPresentationWorkbookMode } from '@/utilities/utilities';

export const TableBuilderSimpleAgGrid: React.FunctionComponent<TableBuilderSimpleAgGridProps> = (props) => {
  const {
    simpleColumns,
    simpleTableData,
    isTransposed,
    isStriped,
    darkMode,
    onColumnResized,
    updateContentMeasurements,
  } = props;

  const agGridRef = useRef<AgGridReact>(null);

  const isInteractiveContent = !_.isNil(updateContentMeasurements);
  const defaultColDef = isInteractiveContent ? CONTENT_DEFAULT_COL_DEF : DEFAULT_COL_DEF;
  const showUnitInSeparateColumn = _.some(
    simpleColumns,
    (column) => column.key === COLUMNS_AND_STATS.valueUnitOfMeasure.key,
  );

  const columnDefs = createSimpleColDefs(props);

  const rows = isTransposed
    ? simpleColumns.map((column, columnIndex) => {
        const agGridRow: Record<string, any> = {};
        _.forEach(simpleTableData, (row, rowIndex) => {
          const cell = row.cells[columnIndex];
          if (simpleColumns[columnIndex].type === TableBuilderColumnType.Text) {
            agGridRow[rowIndex.toString()] = column?.cells?.[row.itemId];
          } else {
            agGridRow[rowIndex.toString()] = getCellValue(
              cell?.value,
              !showUnitInSeparateColumn || column.style === 'percent',
              cell?.units,
              false,
            );
          }
        });

        agGridRow[SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN] = {
          headerComponentParams: getSimpleTableHeaderComponentParams(props, column, columnIndex),
        };

        return agGridRow;
      })
    : _.map(simpleTableData, (row, rowIndex) => {
        const agGridRow: Record<string, any> = {};
        agGridRow[SIMPLE_TABLE_STRIPED_FIELD] = getStripedColor(isStriped, rowIndex, darkMode);
        _.forEach(columnDefs, (def, columnIndex) => {
          const column = simpleColumns[columnIndex];
          const cell = row.cells[columnIndex];

          if (simpleColumns[columnIndex].type === TableBuilderColumnType.Text) {
            agGridRow[def.field!] = column?.cells?.[row.itemId];
          } else {
            agGridRow[def.field!] = getCellValue(
              cell?.value,
              !showUnitInSeparateColumn || column.style === 'percent',
              cell?.units,
              false,
            );
          }
        });

        return agGridRow;
      });

  if (agGridRef.current?.columnApi && !isInteractiveContent) {
    autoSizeColumns(columnDefs, agGridRef.current?.columnApi);
  }

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      <div className="flexFillOverflow overflowAuto">
        <AgGridReact
          ref={agGridRef}
          domLayout={isPresentationWorkbookMode() ? 'print' : 'autoHeight'}
          rowData={rows}
          columnDefs={columnDefs}
          className={classNames({ 'transposed-simple-table': isTransposed })}
          headerHeight={isTransposed ? 0 : undefined}
          defaultColDef={defaultColDef}
          onColumnResized={isInteractiveContent ? undefined : onColumnResized}
          onFirstDataRendered={() => {
            if (isInteractiveContent) {
              setTimeout(() => {
                sizeGridToTableCell(agGridRef.current); // size content to fit container
                updateContentMeasurements?.({ width: calculateWidth(agGridRef.current) }); // ck editor needs its own
                // sizing step
              }, 0);
            }
          }}
          onGridReady={(event) => {
            addScreenshotSetup(event);
          }}
        />
      </div>
    </DndProvider>
  );
};
