import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import _ from 'lodash';
import React from 'react';
import { TableBuilderTextHeader } from '@/tableBuilder/tableComponents/TableBuilderTextHeader.atom';
import { getExtraHeaderProps, getMenuActions } from '@/utilities/tableBuilderHelper.utilities';
import { TableBuilderTextHeaderSimpleProps } from '@/tableBuilder/tableBuilder.types';
import { ThresholdOutputV1 } from '@/sdk';

export const TableBuilderTextHeaderSimple: React.FunctionComponent<TableBuilderTextHeaderSimpleProps> = ({
  simpleColumn: column,
  columnToThresholds,
  hasOnlyStringSeries,
  hasOnlyStringMetrics,
  hasNumericAndStringMetrics,
  hasNumericAndStringSeries,
  isPresentationMode,
  canSort,
  maxSortLevel,
  sortByColumn,
  isViewOnlyMode,
  setHeaderText,
  setColumnFilter,
  distinctStringValueMap,
  textFormatter,
  moveColumn,
  removeColumn,
  columnIndex,
  isTransposed,
  darkMode,
  headers,
  displayRange,
  timezone,
  isAgGrid,
}) => {
  const canEdit = !isPresentationMode && !isViewOnlyMode;
  const extraProps = getExtraHeaderProps(column, headers, displayRange, timezone);
  const isStringColumn =
    _.includes(['string', 'assets', 'fullpath'], column.style) ||
    column.isCustomProperty ||
    (hasOnlyStringSeries && column.key === COLUMNS_AND_STATS['statistics.endValue'].key) ||
    (hasOnlyStringMetrics && column.key === COLUMNS_AND_STATS.metricValue.key);

  let columnThresholds: ThresholdOutputV1[] | undefined;
  if (!!column.stat && !!columnToThresholds[column.stat]) {
    columnThresholds = columnToThresholds[column.stat];
  } else if (column.key === 'metricValue' && !!columnToThresholds['metricValue']) {
    columnThresholds = columnToThresholds['metricValue'];
  } else {
    columnThresholds = undefined;
  }

  // Don't let users filter
  // - stat columns when string and numeric signals are in the table
  // - the metricValue column when string and numeric metrics are in the table
  // - when we are in presentation mode
  const isFilterDisabled =
    (column.key === 'metricValue' && hasNumericAndStringMetrics) ||
    (column.stat && hasNumericAndStringSeries) ||
    isPresentationMode;

  return (
    <TableBuilderTextHeader
      textValue={extraProps.textValue}
      isInput={extraProps.isInput}
      isStatic={extraProps.isStatic}
      onTextChange={(value) => setHeaderText(column.key, value)}
      columnBackgroundColor={column.backgroundColor}
      columnTextAlign={column.textAlign}
      columnTextColor={column.textColor}
      columnTextStyle={column.textStyle}
      headerBackgroundColor={column.headerBackgroundColor}
      headerTextAlign={column.headerTextAlign}
      headerTextColor={column.headerTextColor}
      headerTextStyle={column.headerTextStyle}
      key={columnIndex}
      columnKey={column.key}
      columnIndex={columnIndex}
      canEdit={canEdit}
      isTransposed={isTransposed}
      sort={{
        canSort,
        canSortDisabledTooltip: 'TABLE_BUILDER.ONLY_ON_TABLE_WITH_ONE_ITEM',
        maxSortLevel,
        sortDirection: column.sort?.direction,
        sortLevel: column.sort?.level,
        sortByColumn,
      }}
      setColumnFilter={setColumnFilter}
      columnFilter={column.filter}
      isFilterDisabled={isFilterDisabled}
      distinctStringValues={distinctStringValueMap?.[column.key]}
      menuActions={getMenuActions(column.type, isPresentationMode, isViewOnlyMode)}
      isStringColumn={isStringColumn}
      thresholds={columnThresholds}
      textFormatter={textFormatter}
      moveColumn={moveColumn}
      removeColumn={removeColumn}
      darkMode={darkMode}
      isAgGrid={isAgGrid}
    />
  );
};
