import React, { useRef } from 'react';
import _ from 'lodash';
import { AgGridReact } from '@ag-grid-community/react';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import {
  formatHeader,
  getTextValueForConditionHeader,
  isStartOrEndColumn,
} from '@/utilities/tableBuilderHelper.utilities';
import {
  CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN,
  CONTENT_DEFAULT_COL_DEF,
  DEFAULT_COL_DEF,
  TableBuilderHeaderType,
} from '@/tableBuilder/tableBuilder.constants';
import {
  addScreenshotSetup,
  autoSizeColumns,
  calculateWidth,
  createConditionColDefs,
  getAlternateConditionTableTextHeaderParams,
  getConditionCapsuleFieldName,
  getConditionColumnFieldName,
  getConditionTextHeaderParams,
  sizeGridToTableCell,
} from '@/tableBuilder/tableBuilderAgGrid.utilities';
import {
  DataHeaderProps,
  TableBuilderConditionAgGridProps,
  TableBuilderTextHeaderProps,
} from '@/tableBuilder/tableBuilder.types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isPresentationWorkbookMode } from '@/utilities/utilities';

export const TableBuilderConditionAgGrid: React.FunctionComponent<TableBuilderConditionAgGridProps> = (props) => {
  const {
    tableData,
    columns: { propertyAndStatColumns, columns },
    timezone,
    headers,
    canEdit,
    isTransposed,
    onColumnResized,
    updateContentMeasurements,
  } = props;

  const agGridRef = useRef<AgGridReact>(null);

  const showHeaders = (canEdit && columns.length > 0) || headers?.type !== TableBuilderHeaderType.None;
  const isInteractiveContent = !_.isNil(updateContentMeasurements);
  const defaultColDef = isInteractiveContent ? CONTENT_DEFAULT_COL_DEF : DEFAULT_COL_DEF;

  const columnDefs = createConditionColDefs(props);

  const transposedRows = () =>
    columns
      // The first row is embedded in the column headers, so we skip it
      .slice(1)
      .map((column, columnIndexMinus1) => {
        const columnIndex = columnIndexMinus1 + 1;
        const row: Record<string, any> = {};
        if (showHeaders) {
          const textHeaderProps: TableBuilderTextHeaderProps = getConditionTextHeaderParams(column, columnIndex, props);
          row[CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN] = {
            textHeaderProps,
          };
        }
        _.forEach(tableData.headers, (header) => {
          row[header.key.replace('.', '')] = getTextValueForConditionHeader(header, column);
        });

        return row;
      })
      .concat(
        tableData.capsules.map((capsule) => {
          const row: Record<string, any> = {};

          // Cell for capsule start/end
          if (showHeaders) {
            const dataHeaderProps: DataHeaderProps = {
              headerValue: formatHeader(headers, capsule.property, capsule.startTime, capsule.endTime, timezone),
              isAgrid: true,
            };
            row[CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN] = {
              dataHeaderProps,
            };
          }

          // Cells for each property or statistic for the capsule
          _.forEach(capsule.values, (value, valueIndex) => {
            const header = tableData.headers[valueIndex];
            row[header.key.replace('.', '')] = isStartOrEndColumn(header)
              ? formatHeader(
                  {
                    ...headers,
                    type:
                      header.key === COLUMNS_AND_STATS.startTime.key
                        ? TableBuilderHeaderType.Start
                        : TableBuilderHeaderType.End,
                  },
                  capsule.property,
                  capsule.startTime,
                  capsule.endTime,
                  timezone,
                )
              : value?.value?.toString();
          });

          return row;
        }),
      );

  const normalRows = () =>
    tableData.headers.map((header, rowIndex) => {
      let columnIndex = 0;
      const row: Record<string, any> = {};
      const propertyOrStatColumn = _.find(propertyAndStatColumns, {
        key: header.key,
      });
      _.forEach(columns, (column) => {
        const fieldName = getConditionColumnFieldName(columnIndex);
        row[fieldName] =
          column.key === COLUMNS_AND_STATS.name.key
            ? {
                textHeaderProps: getAlternateConditionTableTextHeaderParams(
                  header,
                  column,
                  propertyOrStatColumn,
                  rowIndex,
                  props,
                ),
              }
            : getTextValueForConditionHeader(header, column);
        columnIndex++;
      });
      _.forEach(tableData.capsules, (capsule, index) => {
        const fieldName = getConditionCapsuleFieldName(index);
        row[fieldName] = isStartOrEndColumn(header)
          ? formatHeader(
              {
                ...headers,
                type:
                  header.key === COLUMNS_AND_STATS.startTime.key
                    ? TableBuilderHeaderType.Start
                    : TableBuilderHeaderType.End,
              },
              capsule.property,
              capsule.startTime,
              capsule.endTime,
              timezone,
            )
          : capsule.values[rowIndex]?.value?.toString();
        columnIndex++;
      });

      return row;
    });

  const rowData = isTransposed ? transposedRows() : normalRows();

  if (agGridRef.current?.columnApi && !isInteractiveContent) {
    autoSizeColumns(columnDefs, agGridRef.current?.columnApi);
  }

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      <div className="flexFillOverflow overflowAuto">
        <AgGridReact
          ref={agGridRef}
          domLayout={isPresentationWorkbookMode() ? 'print' : 'autoHeight'}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onColumnResized={isInteractiveContent ? undefined : onColumnResized}
          onFirstDataRendered={() => {
            if (isInteractiveContent) {
              setTimeout(() => {
                sizeGridToTableCell(agGridRef.current); // size content to fit container
                updateContentMeasurements?.({ width: calculateWidth(agGridRef.current) }); // ck editor needs its own
                // sizing step
              }, 0);
            }
          }}
          onGridReady={(event) => {
            addScreenshotSetup(event);
          }}
        />
      </div>
    </DndProvider>
  );
};
