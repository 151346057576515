import { findParent, findParentSize } from '@/utilities/tableBuilderHelper.utilities';
import classNames from 'classnames';
import _ from 'lodash';
import React, { MutableRefObject } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useDidMount } from 'rooks';

const dndObjectType = 'TableBuilderDataHeader';
const dndDelimiterWidth = 8;
const dndDelimiterColor = '#007960';

interface TableBuilderTextHeaderDraggableProps {
  columnIndex: number;
  columnKey: string;
  isTransposed: boolean;
  moveColumn?: (key: string, newKey: string) => void;
  targetRef: MutableRefObject<HTMLDivElement>;
  children: React.ReactNode;
  isRefAttached?: boolean;
  isEdittable?: boolean;
  isAgGrid?: boolean;
}

const NORMAL_PARENT_ELEMENT = 'TABLE';
const AG_GRID_PARENT_ELEMENT = 'DIV';
const AG_GRID_PARENT_ROW_CLASS = 'ag-row';

const AG_ROOT = 'ag-root';
const AG_HEADER = '.ag-header';
// The main ag-grid body element stretches to fill all available vertical space, so we have to dive down a bit more
const AG_BODY_WITH_CORRECT_HEIGHT = '.ag-full-width-container';

export const TableBuilderTextHeaderDraggable: React.FunctionComponent<TableBuilderTextHeaderDraggableProps> = ({
  columnIndex,
  columnKey,
  isTransposed,
  moveColumn = _.noop,
  targetRef: ref,
  isRefAttached,
  children,
  isEdittable,
  isAgGrid,
}) => {
  const findDelimiterPosition = (item: any): { top: number; left: number; width: number; height: number } => {
    let calculatedHeight: number | undefined;
    if (!isTransposed && isAgGrid) {
      const topLevelParent = findParent(ref.current, AG_GRID_PARENT_ELEMENT, AG_ROOT);
      const headerHeight = topLevelParent.querySelector(AG_HEADER)?.clientHeight;
      const bodyHeight = topLevelParent.querySelector(AG_BODY_WITH_CORRECT_HEIGHT)?.clientHeight;
      if (_.isNumber(headerHeight) && _.isNumber(bodyHeight)) {
        calculatedHeight = headerHeight + bodyHeight;
      }
    }

    return isTransposed
      ? {
          top: item?.columnIndex > columnIndex ? 0 : ref.current?.getBoundingClientRect().height - dndDelimiterWidth,
          left: 0,
          width: findParentSize(
            ref.current,
            isAgGrid ? AG_GRID_PARENT_ELEMENT : NORMAL_PARENT_ELEMENT,
            isAgGrid ? AG_GRID_PARENT_ROW_CLASS : undefined,
          )?.width,
          height: dndDelimiterWidth,
        }
      : {
          top: 0,
          left: item?.columnIndex > columnIndex ? 0 : ref.current?.getBoundingClientRect().width - dndDelimiterWidth,
          width: dndDelimiterWidth,
          height: isAgGrid ? calculatedHeight! : findParentSize(ref.current, NORMAL_PARENT_ELEMENT)?.height,
        };
  };

  const getDraggingDivShadow = () => (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 2,
        opacity: 0.5,
        backgroundColor: '#ffcccc',
      }}
    />
  );

  const [{ isDragging }, connectDrag] = useDrag({
    type: dndObjectType,
    item: { columnIndex, columnKey },
    collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
  });

  const [{ isOver, delimiter }, connectDrop] = useDrop({
    accept: dndObjectType,
    drop: (item: any) => moveColumn(item.columnKey, columnKey),
    collect: (monitor) => {
      if (monitor.isOver()) {
        return {
          isOver: monitor.isOver() && monitor.getItem()?.columnIndex !== columnIndex,
          delimiter: findDelimiterPosition(monitor.getItem()),
        };
      }
      return { isOver: false };
    },
  });

  useDidMount(() => {
    connectDrag(connectDrop(ref));
  });

  const getOverDiv = () => {
    if (!delimiter) {
      return <></>;
    }

    return (
      <div
        style={{
          position: 'absolute',
          top: delimiter.top,
          left: delimiter.left,
          height: delimiter.height,
          width: delimiter.width,
          zIndex: 1,
          opacity: 1,
          backgroundColor: dndDelimiterColor,
        }}
      />
    );
  };

  return (
    <div
      className={classNames('flexColumnContainer', { 'max-width-200': !isEdittable })}
      ref={isRefAttached ? undefined : ref}>
      {children}

      {isEdittable && (
        <>
          {isOver && getOverDiv()}
          {isDragging && getDraggingDivShadow()}
        </>
      )}
    </div>
  );
};
