import React from 'react';
import _ from 'lodash';
import i18next from 'i18next';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import {
  computeCellColors,
  computeCellStyle,
  formatHeader,
  getMenuActionsForAlternativeHeader,
  getStripedColor,
  getTextValueForConditionHeader,
  isStartOrEndColumn,
} from '@/utilities/tableBuilderHelper.utilities';
import {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  ColumnApi,
  EditableCallbackParams,
  GridReadyEvent,
  ICellRendererParams,
  ModuleRegistry,
  NewValueParams,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import {
  BaseTableBuilderTextHeaderSimpleProps,
  ConditionTableHeader,
  ConditionTableValue,
  DataHeaderProps,
  TableBuilderConditionAgGridProps,
  TableBuilderConditionTableProps,
  TableBuilderSimpleAgGridProps,
  TableBuilderTextHeaderProps,
  TableBuilderTextHeaderSimpleProps,
  TableHeaderConditionProps,
} from '@/tableBuilder/tableBuilder.types';
import { TableHeaderCondition } from '@/tableBuilder/ag/TableHeaderCondition';
import { getMenuActionsForTextHeader } from '@/tableBuilder/tableBuilderCondition.utilities';
import { TableBuilderDataHeader } from '@/tableBuilder/tableComponents/TableBuilderDataHeader.atom';
import {
  AG_GRID_METRIC_CLASSES,
  AG_GRID_TEXT_CLASSES,
  CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN,
  SIMPLE_TABLE_STRIPED_FIELD,
  SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN,
  TableBuilderColumnType,
  TableBuilderHeaderType,
} from '@/tableBuilder/tableBuilder.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { TableHeaderSimple, TableHeaderSimpleProps } from '@/tableBuilder/ag/TableHeaderSimple';
import { DEBOUNCE } from '@/core/core.constants';

const TransposedHeaderCellRenderer: React.FunctionComponent<
  ICellRendererParams<any, { headerComponentParams: TableHeaderSimpleProps }>
> = ({ value }) => {
  const headerComponentParams = value?.headerComponentParams;

  return headerComponentParams ? <TableHeaderSimple {...headerComponentParams} /> : <div></div>;
};

const ConditionHeaderRenderer: React.FunctionComponent<
  ICellRendererParams<any, { textHeaderProps?: TableHeaderConditionProps; dataHeaderProps?: DataHeaderProps }>
> = ({ value }) => {
  if (value?.textHeaderProps) {
    return <TableHeaderCondition {...value.textHeaderProps} />;
  }

  if (value?.dataHeaderProps) {
    return <TableBuilderDataHeader {...value.dataHeaderProps} />;
  }

  return <div></div>;
};

export const getSimpleTableHeaderComponentParams = (
  tableProps: TableBuilderSimpleAgGridProps,
  column: any,
  columnIndex: number,
): TableBuilderTextHeaderSimpleProps => {
  const tableHeaderProps = _.pick(tableProps, [
    'columnToThresholds',
    'hasOnlyStringSeries',
    'hasOnlyStringMetrics',
    'hasNumericAndStringMetrics',
    'hasNumericAndStringSeries',
    'isPresentationMode',
    'canSort',
    'maxSortLevel',
    'sortByColumn',
    'isViewOnlyMode',
    'setHeaderText',
    'setColumnFilter',
    'distinctStringValueMap',
    'textFormatter',
    'moveColumn',
    'removeColumn',
    'columnIndex',
    'isTransposed',
    'darkMode',
    'headers',
    'displayRange',
    'timezone',
  ]) as BaseTableBuilderTextHeaderSimpleProps;

  return {
    ...tableHeaderProps,
    simpleColumn: column,
    columnIndex,
    isAgGrid: true,
  };
};

export function createSimpleColDefs(props: TableBuilderSimpleAgGridProps): ColDef[] {
  const {
    otherColumns,
    displayRange,
    isTransposed,
    simpleTableData,
    simpleColumns,
    isStriped,
    darkMode,
    updateContentMeasurements,
  } = props;

  const canEdit = !props.isPresentationMode && !props.isViewOnlyMode;
  const isInteractiveContent = !_.isNil(updateContentMeasurements);

  return isTransposed
    ? Array.of<ColDef>({
        colId: SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN,
        field: SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN,
        width: isInteractiveContent ? undefined : otherColumns[SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN]?.width,
        cellRenderer: TransposedHeaderCellRenderer,
        cellClass: 'transposed-header-cell',
        lockPosition: true,
      }).concat(
        simpleTableData.map((row, rowIndex) => {
          const colId = rowIndex.toString();

          return {
            colId,
            field: colId,
            width: isInteractiveContent ? undefined : otherColumns[colId]?.width,
            lockPosition: true,
            editable: (params: EditableCallbackParams) => {
              const columnIndex = params.node.rowIndex;

              return (
                canEdit && _.isNumber(columnIndex) && simpleColumns[columnIndex].type === TableBuilderColumnType.Text
              );
            },
            onCellValueChanged: (event: NewValueParams) => {
              if (!_.isNumber(event?.node?.rowIndex)) {
                return;
              }

              props.setCellText(
                simpleColumns[event!.node!.rowIndex]?.key,
                event.newValue,
                simpleTableData[rowIndex]?.itemId,
              );
            },
            onCellClicked: (params: CellClickedEvent) => {
              const columnIndex = params.rowIndex;
              if (columnIndex === null) {
                return;
              }

              const metricId = simpleTableData[rowIndex]?.cells[columnIndex]?.metricId;
              if (metricId) {
                props.displayMetricOnTrend(
                  metricId,
                  simpleTableData[rowIndex].itemId,
                  displayRange.start.valueOf(),
                  displayRange.end.valueOf(),
                  params.event,
                );
              }
            },
            cellStyle: (params: CellClassParams) => {
              const columnIndex = params.rowIndex;
              const column = simpleColumns[columnIndex];
              const priorityColor = simpleTableData[rowIndex]?.cells[columnIndex]?.priorityColor;

              return {
                ...computeCellStyle(
                  column.backgroundColor,
                  column.textColor,
                  column.textStyle,
                  column.textAlign,
                  priorityColor,
                  getStripedColor(isStriped, columnIndex, darkMode),
                  darkMode,
                ),
              };
            },
            cellClass: (params: CellClassParams) => {
              const columnIndex = params.rowIndex;
              const metricId = simpleTableData[rowIndex]?.cells[columnIndex]?.metricId;
              if (metricId) {
                return AG_GRID_METRIC_CLASSES;
              }

              if (simpleColumns[columnIndex].type === TableBuilderColumnType.Text) {
                return AG_GRID_TEXT_CLASSES;
              }

              return '';
            },
          };
        }),
      )
    : simpleColumns.map((column, columnIndex) => {
        return {
          colId: column.key,
          // ag-grid doesn't like periods in fields
          field: column.key.replace('.', ''),
          headerName:
            column.header ??
            (column.type === TableBuilderColumnType.Property ? column.key : i18next.t(column.shortTitle)),
          headerComponent: TableHeaderSimple,
          headerComponentParams: getSimpleTableHeaderComponentParams(props, column, columnIndex),
          lockPosition: true,
          // This is surprisingly not exposed anywhere
          index: columnIndex,
          editable: canEdit && column.type === TableBuilderColumnType.Text,
          width: isInteractiveContent ? undefined : column.width,
          onCellValueChanged: (event: NewValueParams) => {
            if (!_.isNumber(event?.node?.rowIndex)) {
              return;
            }

            props.setCellText(column.key, event.newValue, simpleTableData[event!.node!.rowIndex]?.itemId);
          },
          onCellClicked: (params: CellClickedEvent) => {
            const rowIndex = params.rowIndex;
            if (rowIndex === null) {
              return;
            }

            const columnIndex: number = (params.colDef as any).index;
            const metricId = simpleTableData[rowIndex]?.cells[columnIndex]?.metricId;
            if (metricId) {
              props.displayMetricOnTrend(
                metricId,
                simpleTableData[rowIndex].itemId,
                displayRange.start.valueOf(),
                displayRange.end.valueOf(),
                params.event,
              );
            }
          },
          cellStyle: (params: CellClassParams) => {
            const rowIndex = params.rowIndex;
            const columnIndex: number = (params.colDef as any).index;
            const priorityColor = simpleTableData[rowIndex]?.cells[columnIndex]?.priorityColor;

            return {
              ...computeCellStyle(
                column.backgroundColor,
                column.textColor,
                column.textStyle,
                column.textAlign,
                priorityColor,
                params.data[SIMPLE_TABLE_STRIPED_FIELD],
                darkMode,
              ),
            };
          },
          cellClass: (params: CellClassParams) => {
            const rowIndex = params.rowIndex;
            const columnIndex: number = (params.colDef as any).index;
            const metricId = simpleTableData[rowIndex]?.cells[columnIndex]?.metricId;
            if (metricId) {
              return AG_GRID_METRIC_CLASSES;
            }

            if (column.type === TableBuilderColumnType.Text) {
              return AG_GRID_TEXT_CLASSES;
            }

            return '';
          },
        };
      });
}

export function createConditionColDefs(props: TableBuilderConditionAgGridProps): ColDef[] {
  const {
    columns: { columns, propertyAndStatColumns },
    headers,
    tableData,
    timezone,
    canEdit,
    isTransposed,
    otherColumns,
    updateContentMeasurements,
  } = props;

  const showHeaders = (canEdit && columns.length > 0) || headers?.type !== TableBuilderHeaderType.None;
  const isInteractiveContent = !_.isNil(updateContentMeasurements);

  const transposedColumns = () => {
    const nameColumn = columns.find((column) => column.key === COLUMNS_AND_STATS.name.key);

    const headerDef: ColDef[] = showHeaders
      ? [
          {
            colId: CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN,
            field: CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN,
            cellRenderer: ConditionHeaderRenderer,
            headerComponent: TableHeaderCondition,
            headerComponentParams: getConditionTextHeaderParams(nameColumn, 0, props),
            lockPosition: true,
            cellClass: 'transposed-header-cell',
            width: isInteractiveContent ? undefined : otherColumns[CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN]?.width,
          },
        ]
      : [];

    return headerDef.concat(
      tableData.headers.map((header, headerIndex) => {
        const propertyOrStatColumn = _.find(propertyAndStatColumns, {
          key: header.key,
        });

        return {
          colId: header.key,
          field: header.key.replace('.', ''),
          width: isInteractiveContent ? undefined : propertyOrStatColumn?.width,
          headerComponent: ConditionHeaderRenderer,
          headerComponentParams: {
            value: {
              textHeaderProps: getAlternateConditionTableTextHeaderParams(
                header,
                nameColumn,
                propertyOrStatColumn,
                headerIndex,
                props,
              ),
            },
          },
          lockPosition: true,
          // We skip the first row and embed it in the headers due to bugs where certain text editing features don't
          // work if a cell conditionally uses a react component for rendering. This means that we need to increment
          // the row index by 1 when interacting with the [columns] prop.
          editable: (params: EditableCallbackParams) => {
            if (!_.isNumber(params.node.rowIndex)) {
              return false;
            }
            const columnIndex = params.node.rowIndex + 1;
            return canEdit && columnIndex < columns.length && columns[columnIndex].type === TableBuilderColumnType.Text;
          },
          onCellValueChanged: (event: NewValueParams) => {
            if (!_.isNumber(event?.node?.rowIndex)) {
              return;
            }
            const columnIndex = event!.node!.rowIndex + 1;
            props.setCellText(columns[columnIndex!].key, event.newValue, header.key);
          },
          cellStyle: (params: CellClassParams) => {
            if (params.rowIndex === null) {
              return {};
            }

            const rowIndex = params.rowIndex + 1;

            const maybeStripedColor = getStripedColor(props.isStriped, params.rowIndex, !!props.darkMode);
            if (rowIndex < columns.length) {
              const column = columns[rowIndex];
              return {
                ...computeCellStyle(
                  column.backgroundColor,
                  column.textColor,
                  column.textStyle,
                  column.textAlign,
                  undefined,
                  maybeStripedColor,
                  props.darkMode,
                ),
              };
            }

            const capsule = props.tableData.capsules[rowIndex - columns.length];
            if (!capsule) {
              return {};
            }
            const value: ConditionTableValue = capsule.values[headerIndex];
            const cellStyle = isStartOrEndColumn(header)
              ? computeCellColors(maybeStripedColor, props.darkMode)
              : computeCellColors(
                  _.isNil(value.priorityColor) || value.priorityColor === '#ffffff'
                    ? maybeStripedColor
                    : value.priorityColor,
                  props.darkMode,
                );
            return {
              ...cellStyle,
            };
          },
          cellClass: (params: CellClassParams) => {
            const rowIndex = params.rowIndex;
            if (rowIndex === null) {
              return '';
            }

            if (rowIndex < columns.length) {
              if (columns[rowIndex].type === TableBuilderColumnType.Text) {
                return AG_GRID_TEXT_CLASSES;
              } else {
                return '';
              }
            }

            const capsule = props.tableData.capsules[rowIndex - columns.length];
            if (!capsule) {
              return '';
            }
            const value: ConditionTableValue = capsule.values[headerIndex];
            if (value?.formulaItemId) {
              return AG_GRID_METRIC_CLASSES;
            } else {
              return '';
            }
          },
        };
      }),
    );
  };

  const regularColumns = () =>
    columns
      .map<ColDef>((column, columnIndex) => {
        const headerComponentParams = getConditionTextHeaderParams(column, columnIndex, props);

        return {
          colId: column.key,
          headerName: headerComponentParams.textValue,
          field: getConditionColumnFieldName(columnIndex),
          headerComponent: TableHeaderCondition,
          headerComponentParams,
          cellRenderer: column.key === COLUMNS_AND_STATS.name.key ? ConditionHeaderRenderer : undefined,
          lockPosition: true,
          index: columnIndex,
          editable: canEdit && column.type === TableBuilderColumnType.Text,
          width: isInteractiveContent ? undefined : column.width,
          onCellValueChanged: (event: NewValueParams) => {
            if (!_.isNumber(event?.node?.rowIndex)) {
              return;
            }
            props.setCellText(column.key, event.newValue, props.tableData.headers[event!.node!.rowIndex]?.key);
          },
          cellStyle: (params: CellClassParams) => {
            if (typeof params.value === 'object' || !_.isNumber(params?.rowIndex)) {
              return;
            }
            const maybeStripedColor = getStripedColor(props.isStriped, params.rowIndex, !!props.darkMode);
            return {
              ...computeCellStyle(
                column.backgroundColor,
                column.textColor,
                column.textStyle,
                column.textAlign,
                undefined,
                maybeStripedColor,
                props.darkMode,
              ),
            };
          },
          cellClass: () => {
            if (column.type === TableBuilderColumnType.Text) {
              return AG_GRID_TEXT_CLASSES;
            }
          },
        };
      })
      .concat(
        tableData.capsules.map((capsule, index) => {
          const headerName = formatHeader(headers, capsule.property, capsule.startTime, capsule.endTime, timezone);
          const columnIndex = columns.length + index;
          const colId = `capsule-${columnIndex}`;

          return {
            colId,
            headerName,
            field: getConditionCapsuleFieldName(index),
            headerComponent: () => <TableBuilderDataHeader headerValue={headerName} key={columnIndex} isAgrid={true} />,
            lockPosition: true,
            width: isInteractiveContent ? undefined : otherColumns[colId]?.width,
            onCellClicked: (params: CellClickedEvent) => {
              const rowIndex = params.rowIndex;
              if (rowIndex === null) {
                return;
              }

              const header = props.tableData.headers[rowIndex];
              if (isStartOrEndColumn(header)) {
                return;
              }

              const value: ConditionTableValue = capsule.values[rowIndex];
              const formulaItemId = value?.formulaItemId;
              if (formulaItemId) {
                props.displayMetricOnTrend(
                  formulaItemId,
                  value.itemId!,
                  capsule.startTime,
                  capsule.endTime,
                  params.event as any,
                );
              }
            },
            cellStyle: (params: CellClassParams) => {
              const rowIndex = params.rowIndex;
              if (rowIndex === null) {
                return;
              }
              const maybeStripedColor = getStripedColor(props.isStriped, params.rowIndex, !!props.darkMode);
              const header = props.tableData.headers[rowIndex];
              const value: ConditionTableValue = capsule.values[rowIndex];
              const cellStyle = isStartOrEndColumn(header)
                ? computeCellColors(maybeStripedColor, props.darkMode)
                : computeCellColors(
                    _.isNil(value.priorityColor) || value.priorityColor === '#ffffff'
                      ? maybeStripedColor
                      : value.priorityColor,
                    props.darkMode,
                  );
              return {
                ...cellStyle,
              };
            },
            cellClass: (params: CellClassParams) => {
              const rowIndex = params.rowIndex;
              if (rowIndex === null) {
                return;
              }
              const value: ConditionTableValue = capsule.values[rowIndex];
              if (value?.formulaItemId) {
                return AG_GRID_METRIC_CLASSES;
              } else {
                return '';
              }
            },
          };
        }),
      );

  return isTransposed ? transposedColumns() : regularColumns();
}

export function getConditionTextHeaderParams(
  column: any,
  columnIndex: number,
  props: TableBuilderConditionTableProps,
): TableBuilderTextHeaderProps {
  const headerName = column.header ?? (column.key === COLUMNS_AND_STATS.name.key ? '' : i18next.t(column.shortTitle));

  return {
    textValue: headerName,
    isInput: true,
    isAgGrid: true,
    columnIndex,
    columnKey: column.key,
    onTextChange: (value) => props.setCellText(column.key, value),
    columnBackgroundColor: column.backgroundColor,
    columnTextAlign: column.textAlign,
    columnTextColor: column.textColor,
    columnTextStyle: column.textStyle,
    headerBackgroundColor: column.headerBackgroundColor,
    headerTextAlign: column.headerTextAlign,
    headerTextColor: column.headerTextColor,
    headerTextStyle: column.headerTextStyle,
    canEdit: props.canEdit,
    isTransposed: props.isTransposed,
    menuActions: getMenuActionsForTextHeader(props.canEdit),
    textFormatter: props.textFormatter,
    sort: {
      canSort: props.canSort,
      maxSortLevel: props.maxSortLevel,
      sortDirection: column.sort?.direction,
      sortLevel: column.sort?.level,
      sortByColumn: props.sortByColumn,
    },
    showMove: column.key !== COLUMNS_AND_STATS.name.key,
    moveColumn: props.moveColumn,
    removeColumn: props.removeColumn,
    fetchStringColumnValues: props.fetchStringColumnValues,
    darkMode: props.darkMode ?? false,
  };
}

export function getAlternateConditionTableTextHeaderParams(
  header: ConditionTableHeader,
  column: any,
  statOrPropertyColumn: any,
  headerIndex: number,
  props: TableBuilderConditionTableProps,
): TableBuilderTextHeaderProps {
  return {
    textValue: statOrPropertyColumn
      ? statOrPropertyColumn.header ?? header.name
      : getTextValueForConditionHeader(header, column),
    isInput: true,
    isAgGrid: true,
    columnIndex: headerIndex,
    columnKey: statOrPropertyColumn?.key ?? header.key,
    onTextChange: (value: any) => statOrPropertyColumn && props.setHeaderText(statOrPropertyColumn.key, value),
    headerBackgroundColor: column.backgroundColor,
    headerTextAlign: column.textAlign,
    headerTextColor: column.textColor,
    headerTextStyle: column.textStyle,
    canEdit: statOrPropertyColumn && props.canEdit,
    isTransposed: !props.isTransposed,
    isStringColumn: header.isStringColumn,
    isDurationColumn: statOrPropertyColumn?.key === SeeqNames.Properties.Duration,
    isFilterDisabled: props.isPresentationMode,
    distinctStringValues: props.distinctStringValueMap[statOrPropertyColumn?.key ?? header.key],
    thresholds: props.columnToThresholds[header.key],
    fetchStringColumnValues: props.fetchStringColumnValues,
    menuActions: getMenuActionsForAlternativeHeader(props.isPresentationMode, props.canEdit, statOrPropertyColumn),
    sort: {
      canSort: props.canSort,
      maxSortLevel: props.maxSortLevel,
      sortDirection: statOrPropertyColumn
        ? statOrPropertyColumn?.sort?.direction
        : props.itemSorts[header.key]?.sort?.direction,
      sortLevel: statOrPropertyColumn ? statOrPropertyColumn?.sort?.level : props.itemSorts[header.key]?.sort?.level,
      sortByColumn: props.sortByColumn,
    },
    setColumnFilter: props.setColumnFilter,
    columnFilter: statOrPropertyColumn ? statOrPropertyColumn.filter : props.itemFilters[header.key]?.filter,
    removeColumn: statOrPropertyColumn ? props.removeColumn : undefined,
    moveColumn: statOrPropertyColumn ? props.moveColumn : undefined,
    showMove: !isStartOrEndColumn(statOrPropertyColumn),
    darkMode: props.darkMode ?? false,
  };
}

export function addScreenshotSetup(event?: GridReadyEvent) {
  if (!headlessRenderMode()) {
    return;
  }

  // Add the screenshotSizeToContent class to the root wrapper because the parent div does not have the correct
  // width and makes screenshots too wide
  const rootContainer = document.querySelector('.ag-root-wrapper') as HTMLElement;
  rootContainer?.classList.add('screenshotSizeToContent');
}

export function getConditionColumnFieldName(index: number): string {
  return `column${index}`;
}

export function getConditionCapsuleFieldName(index: number): string {
  return `capsule${index}`;
}

export function initializeAgGrid() {
  ModuleRegistry.registerModules([ClientSideRowModelModule]);
}

export const autoSizeColumns = _.debounce((columnDefs: ColDef[], columnApi: ColumnApi) => {
  const columnsToAutoSize = columnDefs
    .filter((columnDef) => !_.isNil(columnDef.colId) && _.isNil(columnDef.width))
    .map((columnDef) => columnDef.colId!);

  columnApi.autoSizeColumns(columnsToAutoSize);
}, DEBOUNCE.MEDIUM);

export const sizeGridToTableCell = (agGrid: AgGridReact | null) => {
  if (agGrid) {
    agGrid.api.sizeColumnsToFit();
  }
};

export const calculateWidth = (agGrid: AgGridReact | null) => {
  if (agGrid) {
    return agGrid.columnApi
      .getColumnState()
      .map((s) => s.width)
      .reduce((totalWidth, currentWidth) => totalWidth! + currentWidth!, 0)!;
  }
};
