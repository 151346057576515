import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { PropertyColumn as FormulaPropertyColumn } from '@/utilities/formula.constants';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import {
  ConditionTableCapsule,
  ConditionTableHeader,
  ConditionTableValue,
  SimpleTableCell,
  SimpleTableRow,
  TableBuilderHeaders,
} from '@/tableBuilder/tableBuilder.types';
import { RangeExport } from '@/trendData/duration.store';
import { ColDef } from '@ag-grid-community/core';

export enum TextHeaderMenuActions {
  Style = 'style',
  Rename = 'rename',
  Remove = 'remove',
  Filter = 'filter',
  Sort = 'sort',
}

export enum TableBuilderMode {
  Simple = 'simple',
  Condition = 'condition',
}

export enum TableBuilderColumnType {
  Text = 'text', // Column that will be filled with custom text per metric
  Property = 'property', // Column that will be filled with the value of a specified item property
  CapsuleProperty = 'capsuleProperty', // Column that will be filled with the value of a specified capsule property
}

export enum TableBuilderHeaderType {
  None = 'none',
  Start = 'start',
  End = 'end',
  StartEnd = 'startEnd',
  CapsuleProperty = 'capsuleProperty',
}

export const STRIPED_CELL_COLOR = '#f9f9f9';
export const STRIPED_CELL_COLOR_DARK = '#000';
export const PRIORITY_COLUMN_NAME = 'Priority';
export const PRIORITY_NAME_COLUMN_NAME = 'Priority Name';
export const COLOR_COLUMN_NAME = 'Color';
export const TABLE_BUILDER = 'Table Builder';

export interface MetricPropertyColumn extends FormulaPropertyColumn {
  /**
   * The name of the property on the metric capsule.
   */
  metricProperty?: string;
  /**
   * The parameter expression used to get information about the metric item
   */
  expression?: string;
  /** A filter on the metric property column (optional) */
  filter?: TableColumnFilter;
}

export const SIMPLE_TABLE_ID_COLUMN = 'itemId';
export const ITEM_UOM = 'itemUom';
export const SIMPLE_TABLE_BUILDER_EXTRA_CUSTOMIZATION_COLUMNS = ['metricValue'];
export const SIMPLE_METRIC_FALLBACK_STATISTIC = { key: 'statistics.endValue' };
export const CONDITION_EXTRA_COLUMNS = [
  COLUMNS_AND_STATS.startTime.key,
  COLUMNS_AND_STATS.endTime.key,
  COLUMNS_AND_STATS.nameExpression.key,
  COLUMNS_AND_STATS.asset.key,
];
// Prefix added to columns in the formula so that they don't conflict with the names of actual properties added by
// the user
export const COLUMN_PREFIX = '__';
export const withDefaultFormatting = (column: any) => ({
  ...column,
  headerTextAlign: 'center',
  headerTextStyle: ['bold'],
});
export const SIMPLE_TABLE_DEFAULT_COLUMNS = [
  withDefaultFormatting(COLUMNS_AND_STATS.name),
  withDefaultFormatting(COLUMNS_AND_STATS['statistics.average']),
];
export const PREDEFINED_COLUMN_INDEX = {
  [COLUMNS_AND_STATS.name.key]: 0,
  [COLUMNS_AND_STATS.asset.key]: 1,
};
export const CONDITION_TABLE_DEFAULT_COLUMNS = [withDefaultFormatting({ key: COLUMNS_AND_STATS.name.key })];
export const CONDITION_METRIC_COLUMNS: MetricPropertyColumn[] = [
  {
    key: 'value',
    propertyName: SeeqNames.CapsuleProperties.Value,
    metricProperty: SeeqNames.CapsuleProperties.Value,
    invalidsFirst: true,
  },
  {
    key: 'priorityColor',
    propertyName: COLOR_COLUMN_NAME,
    metricProperty: COLOR_COLUMN_NAME,
  },
  {
    key: 'priority',
    propertyName: PRIORITY_COLUMN_NAME,
    metricProperty: PRIORITY_COLUMN_NAME,
  },
  // CRAB-27186 - This column is not used, but must be unique per metric to work with the mergeRows() algorithm
  {
    key: 'priorityName',
    propertyName: PRIORITY_NAME_COLUMN_NAME,
    metricProperty: PRIORITY_NAME_COLUMN_NAME,
  },
  {
    key: 'itemId',
    propertyName: 'itemId',
    expression: `property('${SeeqNames.Properties.Id}')`,
  },
];

export const DEFAULT_BACKGROUND_LIGHT = '#ffffff';
export const DEFAULT_BACKGROUND_DARK = 'rgb(36, 37, 37)';

export const DEFAULT_TEXT_COLOR_LIGHT = '#000';
export const DEFAULT_TEXT_COLOR_DARK = 'rgb(194, 188, 176)';

export const SIMPLE_TABLE_TRANSPOSED_HEADER_COLUMN = 'simpleTableHeaderColumn';
export const CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN = 'conditionTableHeaderColumn';

export interface SimpleDataCellHelperProperties {
  row: SimpleTableRow;
  column: any;
  cell?: SimpleTableCell;
  maybeStripedColor: string | undefined;
  key: number;
  columnIndex: number;
  darkMode: boolean;
  showUnitInSeparateColumn: boolean;
  displayMetricOnTrend: (metricId: string, formulaItemId: string, start: number, end: number, event: any) => void;
  displayRange: RangeExport;
  isAgGrid?: boolean;
}

export interface SimpleTextCellHelperProperties {
  row: SimpleTableRow;
  column: any;
  cell?: SimpleTableCell;
  maybeStripedColor: string | undefined;
  key: number;
  columnIndex: number;
  canEdit: boolean;
  setCellText: (key: string, value: string, id?: string) => void;
  darkMode: boolean;
  isAgGrid?: boolean;
}

export interface ConditionDataCellHelperProperties {
  header: ConditionTableHeader;
  capsule: ConditionTableCapsule;
  value: ConditionTableValue;
  maybeStripedColor: string | undefined;
  key: number;
  columnIndex: number;
  darkMode: boolean;
  displayMetricOnTrend: (
    metricId: string,
    formulaItemId: string,
    start: number,
    end: number,
    event: React.MouseEvent,
  ) => void;
  showUnitInSeparateColumn: boolean;
  isAgGrid?: boolean;
}

export interface ConditionTimeCellHelperProperties {
  header: ConditionTableHeader;
  headers: TableBuilderHeaders;
  capsule: ConditionTableCapsule;
  maybeStripedColor: string | undefined;
  key: number;
  columnIndex: number;
  timezone: { name: string };
  darkMode: boolean;
  isAgGrid?: boolean;
}

export const AG_GRID_METRIC_CLASSES = 'cursorPointer text-underline-onhover';
export const AG_GRID_TEXT_CLASSES = 'cursorText';
export const NULL_PLACEHOLDER = '-';
export const SIMPLE_TABLE_STRIPED_FIELD = 'seeqTableStriping';
export const DEFAULT_COL_DEF: ColDef = {
  resizable: true,
  autoHeight: true,
  wrapText: true,
  autoHeaderHeight: true,
  wrapHeaderText: true,
};
/** Interactive content does not behave well with `autoHeight` and `wrapText` properties enabled */
export const CONTENT_DEFAULT_COL_DEF: ColDef = { resizable: true };
