// @ts-strict-ignore
import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { TableBuilderHeaderRow } from '@/tableBuilder/tableComponents/TableBuilderHeaderRow.molecule';
import { TableBuilderRow } from '@/tableBuilder/tableComponents/TableBuilderRow.molecule';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableBuilderNoResultsRow } from '@/tableBuilder/TableBuilderNoResultsRow.atom';
import { getStripedColor, renderSimpleDataCell, renderSimpleTextCell } from '@/utilities/tableBuilderHelper.utilities';
import { TableBuilderColumnType, TableBuilderHeaderType } from '@/tableBuilder/tableBuilder.constants';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import { SimpleTableCell, TableBuilderSimpleTableProps } from '@/tableBuilder/tableBuilder.types';
import { TableBuilderTextHeaderSimple } from './tableComponents/TableBuilderTextHeaderSimple';

export const TableBuilderSimpleTable: React.FunctionComponent<TableBuilderSimpleTableProps> = ({
  simpleTableData,
  isLoading,
  simpleColumns: columns,
  headers,
  isStriped,
  isTransposed,
  isPresentationMode,
  isViewOnlyMode,
  displayRange,
  timezone,
  displayMetricOnTrend,
  setCellText,
  setHeaderText,
  setColumnFilter,
  hasNumericAndStringSeries,
  hasOnlyStringSeries,
  hasNumericAndStringMetrics,
  hasOnlyStringMetrics,
  columnToThresholds,
  distinctStringValueMap,
  textFormatter,
  canSort,
  maxSortLevel,
  moveColumn,
  removeColumn,
  sortByColumn,
  darkMode,
}) => {
  const canEdit = !isPresentationMode && !isViewOnlyMode;

  const showUnitInSeparateColumn = _.some(columns, (column) => column.key === COLUMNS_AND_STATS.valueUnitOfMeasure.key);

  const textHeaders = columns.map((column, columnIndex) => (
    <TableBuilderTextHeaderSimple
      key={columnIndex}
      columnIndex={columnIndex}
      simpleColumn={column}
      columnToThresholds={columnToThresholds}
      hasOnlyStringSeries={hasOnlyStringSeries}
      hasOnlyStringMetrics={hasOnlyStringMetrics}
      hasNumericAndStringMetrics={hasNumericAndStringMetrics}
      hasNumericAndStringSeries={hasNumericAndStringSeries}
      isPresentationMode={isPresentationMode}
      canSort={canSort}
      maxSortLevel={maxSortLevel}
      sortByColumn={sortByColumn}
      isViewOnlyMode={isViewOnlyMode}
      setHeaderText={setHeaderText}
      setColumnFilter={setColumnFilter}
      distinctStringValueMap={distinctStringValueMap}
      textFormatter={textFormatter}
      moveColumn={moveColumn}
      removeColumn={removeColumn}
      isTransposed={isTransposed}
      darkMode={darkMode}
      headers={headers}
      displayRange={displayRange}
      timezone={timezone}
    />
  ));

  const createTransposedTableRows = () => {
    const rows = _.map(columns, (column, columnIndex) => {
      const headerProps = { ...textHeaders[columnIndex].props, key: 0 };

      const maybeStripedColor = getStripedColor(isStriped, columnIndex, darkMode);

      // allocate unique key values to avoid duplicate key error. start with next headerCell.key
      let key = headerProps.key + 1;
      const textCells: any = _.map(simpleTableData, (row) => {
        const cell = row.cells[columnIndex] ?? ({} as SimpleTableCell);
        const element =
          column.type === TableBuilderColumnType.Text
            ? renderSimpleTextCell({
                row,
                column,
                cell,
                maybeStripedColor,
                key,
                columnIndex,
                canEdit,
                setCellText,
                darkMode,
              })
            : renderSimpleDataCell({
                row,
                column,
                cell,
                maybeStripedColor,
                key,
                columnIndex,
                darkMode,
                showUnitInSeparateColumn,
                displayMetricOnTrend,
                displayRange,
              });
        key++;

        return element;
      });

      return (
        <TableBuilderHeaderRow key={columnIndex}>
          {headers.type !== TableBuilderHeaderType.None && <TableBuilderTextHeaderSimple {...headerProps} />}
          {textCells}
        </TableBuilderHeaderRow>
      );
    });

    if (!isLoading && _.isEmpty(simpleTableData)) {
      rows.push(<TableBuilderNoResultsRow key={columns.length} colspan={1} />);
    }

    return rows;
  };

  const createNonTransposedTableRows = () => {
    const rows = _.map(simpleTableData, (row, rowIndex) => {
      const maybeStripedColor = getStripedColor(isStriped, rowIndex, darkMode);
      const cells = _.map(columns, (column, columnIndex) => {
        const cell = row.cells[columnIndex] ?? ({} as SimpleTableCell);
        return column.type === TableBuilderColumnType.Text
          ? renderSimpleTextCell({
              row,
              column,
              cell,
              maybeStripedColor,
              key: columnIndex,
              columnIndex,
              canEdit,
              setCellText,
              darkMode,
            })
          : renderSimpleDataCell({
              row,
              column,
              cell,
              maybeStripedColor,
              key: columnIndex,
              columnIndex,
              darkMode,
              showUnitInSeparateColumn,
              displayMetricOnTrend,
              displayRange,
            });
      });
      return <TableBuilderRow key={rowIndex}>{cells}</TableBuilderRow>;
    });

    if (!isLoading && _.isEmpty(simpleTableData)) {
      rows.push(<TableBuilderNoResultsRow key={columns.length} colspan={columns.length} />);
    }

    return rows;
  };

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      <table
        data-testid={isTransposed ? 'simpleTableTransposed' : 'simpleTable'}
        className={classNames(
          'table table-md-condensed width-auto screenshotSizeToContent',
          isTransposed ? 'table-bordered-first-column' : 'table-bordered',
          isTransposed ? 'fixedColumnTable' : 'fixedHeaderTable',
        )}>
        {isTransposed && <tbody>{createTransposedTableRows()}</tbody>}
        {!isTransposed && (
          <>
            <thead>
              {headers.type !== TableBuilderHeaderType.None && (
                <TableBuilderHeaderRow>{textHeaders}</TableBuilderHeaderRow>
              )}
            </thead>
            <tbody>{createNonTransposedTableRows()}</tbody>
          </>
        )}
      </table>
    </DndProvider>
  );
};
