// @ts-strict-ignore
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { Icon } from '@/core/Icon.atom';
import { EditableText } from '@/core/EditableText.atom';
import { TableBuilderTextHeaderMenu } from '@/tableBuilder/tableComponents/TableBuilderTextHeaderMenu.atom';
import { TableFilterIcon } from '@/core/tableUtilities/TableFilterIcon.organism';
import { TableBuilderSortIcon } from '@/tableBuilder/tableComponents/TableBuilderSortIcon.atom';
import { computeCellStyle, getMostReadableIconType } from '@/utilities/tableBuilderHelper.utilities';
import { TextHeaderMenuActions } from '@/tableBuilder/tableBuilder.constants';
import { TableBuilderTextHeaderDraggable } from '@/tableBuilder/tableComponents/TableBuilderTextHeaderDraggable';
import { TableBuilderTextHeaderProps } from '@/tableBuilder/tableBuilder.types';
import classNames from 'classnames';

const DATA_CANCELLATION_GROUP = 'tableBuilder';

export const TableBuilderTextHeader: React.FunctionComponent<TableBuilderTextHeaderProps> = (props) => {
  const {
    canEdit,
    columnIndex,
    columnKey,
    columnBackgroundColor,
    columnTextAlign,
    columnTextColor,
    columnTextStyle,
    headerBackgroundColor,
    headerTextAlign,
    headerTextColor,
    headerTextStyle,
    isInput,
    isStatic = false,
    isTransposed,
    sort,
    textFormatter,
    textValue,
    menuActions = _.values(TextHeaderMenuActions),
    showMove = true,
    moveColumn,
    removeColumn,
    onTextChange,
    setColumnFilter,
    fetchStringColumnValues = _.noop,
    columnFilter = undefined,
    isFilterDisabled = false,
    filterHelpText = undefined,
    isStringColumn = false,
    isDurationColumn = false,
    distinctStringValues = undefined,
    thresholds = undefined,
    darkMode = false,
    isAgGrid = false,
  } = props;

  const [forceEdit, setForceEdit] = useState(false);

  // ref is used:
  // - for drag and drop
  // - to open the text formatter menu relative to this component
  const ref = useRef<HTMLTableDataCellElement>(null);

  // use Object to avoid TS2322: Type 'string' is not assignable to type 'TextAlignProperty'.
  const style: Object = computeCellStyle(
    headerBackgroundColor,
    headerTextColor,
    headerTextStyle,
    headerTextAlign,
    undefined,
    undefined,
    darkMode,
  );

  const renderMenu = () => {
    return (
      !_.isEmpty(menuActions) && (
        <TableBuilderTextHeaderMenu
          canRenameHeader={!isStatic}
          columnKey={columnKey}
          columnBackgroundColor={columnBackgroundColor}
          columnTextAlign={columnTextAlign}
          columnTextColor={columnTextColor}
          columnTextStyle={columnTextStyle}
          headerBackgroundColor={headerBackgroundColor}
          headerTextAlign={headerTextAlign}
          headerTextColor={headerTextColor}
          headerTextStyle={headerTextStyle}
          isTransposed={isTransposed}
          target={ref}
          textFormatter={textFormatter}
          editHeaderValue={() => setForceEdit(true)}
          removeColumn={removeColumn}
          canSort={sort?.canSort}
          canSortDisabledTooltip={sort?.canSortDisabledTooltip}
          sortByColumn={sort?.sortByColumn}
          sortDirection={sort?.sortDirection}
          setColumnFilter={setColumnFilter}
          columnFilter={columnFilter}
          actions={menuActions}
          isFilterDisabled={isFilterDisabled}
          filterHelpText={filterHelpText}
          isStringColumn={isStringColumn}
          isDurationColumn={isDurationColumn}
          fetchStringColumnValues={fetchStringColumnValues}
          distinctStringValues={distinctStringValues}
          thresholds={thresholds}
        />
      )
    );
  };

  const renderDragAndDrop = () => {
    return (
      showMove &&
      moveColumn && (
        <span className="sq-icon-hover" style={{ cursor: 'grab' }}>
          <Icon icon="fa-arrows" extraClassNames="fa-fw sq-icon-hover" type="gray" />
        </span>
      )
    );
  };

  const renderFilter = () => {
    return (
      <TableFilterIcon
        columnKey={columnKey}
        setColumnFilter={setColumnFilter}
        iconType={getMostReadableIconType(headerBackgroundColor)}
        columnFilter={columnFilter}
        isFilterDisabled={isFilterDisabled}
        isStringColumn={isStringColumn}
        isDurationColumn={isDurationColumn}
        distinctStringValues={distinctStringValues}
        helpText={filterHelpText}
        onIconClick={() => fetchStringColumnValues(columnKey, isStringColumn, DATA_CANCELLATION_GROUP)}
        thresholds={thresholds}
      />
    );
  };

  const renderSort = () => {
    return (
      <TableBuilderSortIcon
        active={sort?.canSort}
        direction={sort?.sortDirection}
        columnKey={columnKey}
        headerBackgroundColor={headerBackgroundColor}
        level={sort?.sortLevel}
        setDirection={sort?.sortByColumn}
        showLevel={sort?.maxSortLevel > 1}
      />
    );
  };

  if (canEdit && (isInput || isStatic)) {
    const innerContent = (
      <>
        <div className="ml5 mr5 flexFillOverflow">
          {isInput ? (
            <EditableText
              allowEditing={true}
              allowEmptyValue={true}
              forceEdit={forceEdit}
              inputClasses="flexFill"
              textClasses={classNames('mb0 textAlignLeft', { 'nowrap': !isAgGrid, 'break-word': isAgGrid })}
              testId="columnName"
              value={textValue}
              onUpdate={(value) => {
                setForceEdit(false);
                onTextChange(value);
              }}
            />
          ) : (
            textValue
          )}
        </div>

        <div className="flexColumnContainer">
          {renderFilter()}
          {renderSort()}
          {renderDragAndDrop()}
          {renderMenu()}
        </div>
      </>
    );

    const draggable = (
      <TableBuilderTextHeaderDraggable
        targetRef={ref}
        isEdittable={true}
        isRefAttached={true}
        columnIndex={columnIndex}
        columnKey={columnKey}
        isTransposed={isTransposed}
        isAgGrid={isAgGrid}
        moveColumn={moveColumn}>
        {innerContent}
      </TableBuilderTextHeaderDraggable>
    );

    const className = 'forceVerticalAlignMiddle';
    const dataTestId = 'tableBuilderTextHeader';

    return isAgGrid ? (
      <div style={style} ref={ref} className={className} data-testid={dataTestId}>
        {draggable}
      </div>
    ) : (
      <td style={style} ref={ref} className={className} data-testid={dataTestId}>
        {draggable}
      </td>
    );
  }

  const innerContent = (
    <>
      <div className="ml5 mr5 flexFillOverflow">{textValue}</div>
      <div className="flexColumnContainer">
        {renderFilter()}
        {renderSort()}
        {renderMenu()}
      </div>
    </>
  );
  const className = 'forceVerticalAlignMiddle';
  const dataTestId = 'tableBuilderTextHeader';

  const draggable = (
    <TableBuilderTextHeaderDraggable
      targetRef={ref}
      columnIndex={columnIndex}
      columnKey={columnKey}
      isTransposed={isTransposed}
      isAgGrid={isAgGrid}
      moveColumn={moveColumn}>
      {innerContent}
    </TableBuilderTextHeaderDraggable>
  );

  return isAgGrid ? (
    <div className={className} data-testid={dataTestId} style={style}>
      {draggable}
    </div>
  ) : (
    <td className={className} data-testid={dataTestId} style={style}>
      {draggable}
    </td>
  );
};
