import _ from 'lodash';
import React from 'react';
import { TableBuilderTextInput } from '@/tableBuilder/tableComponents/TableBuilderTextInput.atom';

export interface TextCellIF {
  itemName?: string;
  itemUom?: string | undefined;
  textValue: string;
  columnIndex: number;
  onTextChange?: (value: string) => void;
  style: Record<string, string>;
  canEditCellText?: boolean;
  isAgGrid?: boolean;
}

export const TableBuilderTextCell: React.FunctionComponent<TextCellIF> = (props) => {
  const { columnIndex, textValue, onTextChange = _.noop, style, canEditCellText, isAgGrid = false } = props;

  const innerContent = (
    <>
      {canEditCellText && (
        <div className="tableBuilderTextInput ml5 mr5" data-testid="textInput">
          <TableBuilderTextInput
            value={textValue}
            onChange={(value) => onTextChange(value)}
            tabIndex={columnIndex + 1}
            style={style}
          />
        </div>
      )}
      {!canEditCellText && <>{textValue}</>}
    </>
  );

  const className = 'forceVerticalAlignMiddle ';
  const dataTestId = 'tableBuilderTextCell';
  return isAgGrid ? (
    <div className={`flexColumnContainer flexAlignCenter ${className}`} data-testid={dataTestId} style={style}>
      <div>{innerContent}</div>
    </div>
  ) : (
    <td className={`forceVerticalAlignMiddle ${className}`} data-testid={dataTestId} style={style}>
      {innerContent}
    </td>
  );
};
