// @ts-strict-ignore
import _ from 'lodash';
import React from 'react';
import { TableBuilderTextCell } from '@/tableBuilder/tableComponents/TableBuilderTextCell.atom';
import { TableBuilderDataCell } from '@/tableBuilder/tableComponents/TableBuilderDataCell.atom';
import { TableBuilderRow } from '@/tableBuilder/tableComponents/TableBuilderRow.molecule';
import { TableBuilderTextHeader } from '@/tableBuilder/tableComponents/TableBuilderTextHeader.atom';
import { TableBuilderDataHeader } from '@/tableBuilder/tableComponents/TableBuilderDataHeader.atom';
import { TableBuilderHeaderRow } from '@/tableBuilder/tableComponents/TableBuilderHeaderRow.molecule';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableBuilderNoResultsRow } from '@/tableBuilder/TableBuilderNoResultsRow.atom';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import {
  computeCellColors,
  computeCellStyle,
  formatHeader,
  getMenuActionsForAlternativeHeader,
  getStripedColor,
  getTextValueForConditionHeader,
  isStartOrEndColumn,
  renderConditionDataCell,
  renderConditionTimeCell,
} from '@/utilities/tableBuilderHelper.utilities';
import { TableBuilderColumnType, TableBuilderHeaderType } from '@/tableBuilder/tableBuilder.constants';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import { TableBuilderConditionTableProps } from '@/tableBuilder/tableBuilder.types';
import { getMenuActionsForTextHeader } from '@/tableBuilder/tableBuilderCondition.utilities';

export const TableBuilderConditionTable: React.FunctionComponent<TableBuilderConditionTableProps> = (props) => {
  const {
    tableData,
    isLoading,
    headers,
    columns: allColumns,
    isTransposed,
    isStriped,
    isPresentationMode,
    canEdit,
    timezone,
    setCellText,
    setHeaderText,
    setColumnFilter,
    fetchStringColumnValues,
    itemFilters,
    columnToThresholds,
    distinctStringValueMap,
    textFormatter,
    itemSorts,
    canSort,
    maxSortLevel,
    sortByColumn,
    moveColumn,
    removeColumn,
    displayMetricOnTrend,
    darkMode,
  } = props;
  const { propertyAndStatColumns, columns } = allColumns;

  const { t } = useTranslation();

  const showUnitInSeparateColumn = _.some(columns, (column) => column.key === COLUMNS_AND_STATS.valueUnitOfMeasure.key);
  const showHeaders = (canEdit && columns.length > 0) || headers?.type !== TableBuilderHeaderType.None;

  const renderTextHeader = (column, columnIndex, key) => (
    <TableBuilderTextHeader
      textValue={column.header ?? (column.key === COLUMNS_AND_STATS.name.key ? '' : t(column.shortTitle))}
      isInput={true}
      key={key}
      columnIndex={columnIndex}
      columnKey={column.key}
      onTextChange={(value) => setCellText(column.key, value)}
      columnBackgroundColor={column.backgroundColor}
      columnTextAlign={column.textAlign}
      columnTextColor={column.textColor}
      columnTextStyle={column.textStyle}
      headerBackgroundColor={column.headerBackgroundColor}
      headerTextAlign={column.headerTextAlign}
      headerTextColor={column.headerTextColor}
      headerTextStyle={column.headerTextStyle}
      canEdit={canEdit}
      isTransposed={isTransposed}
      menuActions={getMenuActionsForTextHeader(canEdit)}
      textFormatter={textFormatter}
      sort={{
        canSort,
        maxSortLevel,
        sortDirection: column.sort?.direction,
        sortLevel: column.sort?.level,
        sortByColumn,
      }}
      showMove={column.key !== COLUMNS_AND_STATS.name.key}
      moveColumn={moveColumn}
      removeColumn={removeColumn}
      fetchStringColumnValues={fetchStringColumnValues}
      darkMode={darkMode}
    />
  );

  const renderAlternateTextHeader = (header, column, statOrPropertyColumn, headerIndex, key) => (
    <TableBuilderTextHeader
      textValue={
        statOrPropertyColumn
          ? statOrPropertyColumn.header ?? header.name
          : getTextValueForConditionHeader(header, column)
      }
      isInput={true}
      key={key}
      columnIndex={headerIndex}
      columnKey={statOrPropertyColumn?.key ?? header.key}
      onTextChange={(value) => statOrPropertyColumn && setHeaderText(statOrPropertyColumn.key, value)}
      headerBackgroundColor={column.backgroundColor}
      headerTextAlign={column.textAlign}
      headerTextColor={column.textColor}
      headerTextStyle={column.textStyle}
      canEdit={statOrPropertyColumn && canEdit}
      isTransposed={!isTransposed}
      isStringColumn={header.isStringColumn}
      isDurationColumn={statOrPropertyColumn?.key === SeeqNames.Properties.Duration}
      isFilterDisabled={isPresentationMode}
      distinctStringValues={distinctStringValueMap[statOrPropertyColumn?.key ?? header.key]}
      thresholds={columnToThresholds[header.key]}
      fetchStringColumnValues={fetchStringColumnValues}
      menuActions={getMenuActionsForAlternativeHeader(isPresentationMode, canEdit, statOrPropertyColumn)}
      sort={{
        canSort,
        maxSortLevel,
        sortDirection: statOrPropertyColumn
          ? statOrPropertyColumn?.sort?.direction
          : itemSorts[header.key]?.sort?.direction,
        sortLevel: statOrPropertyColumn ? statOrPropertyColumn?.sort?.level : itemSorts[header.key]?.sort?.level,
        sortByColumn,
      }}
      setColumnFilter={setColumnFilter}
      columnFilter={statOrPropertyColumn ? statOrPropertyColumn.filter : itemFilters[header.key]?.filter}
      removeColumn={statOrPropertyColumn ? removeColumn : undefined}
      moveColumn={statOrPropertyColumn ? moveColumn : undefined}
      showMove={!isStartOrEndColumn(statOrPropertyColumn)}
      darkMode={darkMode}
    />
  );

  // Header row. One cell for each custom column and for each capsule
  const createHeaderRow = () => {
    let columnIndex = 0;
    const cells = _.map(columns, (column) => {
      const header = renderTextHeader(column, columnIndex, columnIndex);
      columnIndex++;
      return header;
    }).concat(
      _.map(tableData?.capsules, (capsule) => (
        <TableBuilderDataHeader
          headerValue={formatHeader(headers, capsule.property, capsule.startTime, capsule.endTime, timezone)}
          key={columnIndex++}
        />
      )),
    );

    return <TableBuilderHeaderRow>{cells}</TableBuilderHeaderRow>;
  };

  // Header rows for transposed version. One row per "column" that is enabled.
  const createTransposedHeaderRows = () =>
    _.map(columns, (row, headerIndex) => {
      let columnIndex = 0;
      const cells: JSX.Element[] = [];
      if (showHeaders) {
        cells.push(renderTextHeader(row, headerIndex, columnIndex));
        columnIndex++;
      }

      _.forEach(tableData.headers, (header) => {
        const propertyOrStatColumn = _.find(propertyAndStatColumns, {
          key: header.key,
        });
        cells.push(
          row.key === COLUMNS_AND_STATS.name.key ? (
            renderAlternateTextHeader(header, row, propertyOrStatColumn, columnIndex, columnIndex)
          ) : (
            <TableBuilderTextCell
              key={columnIndex}
              columnIndex={columnIndex}
              textValue={getTextValueForConditionHeader(header, row)}
              onTextChange={(value) => setCellText(row.key, value, header.key)}
              style={computeCellStyle(
                row.backgroundColor,
                row.textColor,
                row.textStyle,
                row.textAlign,
                undefined,
                undefined,
                darkMode,
              )}
              canEditCellText={canEdit && row.type === TableBuilderColumnType.Text}
            />
          ),
        );
        columnIndex++;
      });

      return <TableBuilderHeaderRow key={headerIndex}>{cells}</TableBuilderHeaderRow>;
    });

  // Main table rows. One row per statistic with values per capsule
  const createTableRows = () => {
    const rows = _.map(tableData.headers, (header, rowIndex) => {
      const maybeStripedColor = getStripedColor(isStriped, rowIndex, darkMode);
      let columnIndex = 0;
      const propertyOrStatColumn = _.find(propertyAndStatColumns, {
        key: header.key,
      });
      const cells: JSX.Element[] = _.map(columns, (column) => {
        const cell =
          column.key === COLUMNS_AND_STATS.name.key ? (
            renderAlternateTextHeader(header, column, propertyOrStatColumn, rowIndex, columnIndex)
          ) : (
            <TableBuilderTextCell
              key={columnIndex}
              columnIndex={columnIndex}
              textValue={getTextValueForConditionHeader(header, column)}
              onTextChange={(value) => setCellText(column.key, value, header.key)}
              style={computeCellStyle(
                column.backgroundColor,
                column.textColor,
                column.textStyle,
                column.textAlign,
                undefined,
                maybeStripedColor,
                darkMode,
              )}
              canEditCellText={canEdit && column.type === TableBuilderColumnType.Text}
            />
          );
        columnIndex++;
        return cell;
      }).concat(
        _.map(tableData.capsules, (capsule) => {
          const cell = isStartOrEndColumn(header)
            ? renderConditionTimeCell({
                header,
                headers,
                capsule,
                maybeStripedColor,
                columnIndex,
                key: columnIndex,
                timezone,
                darkMode,
              })
            : renderConditionDataCell({
                header,
                capsule,
                value: capsule.values[rowIndex],
                maybeStripedColor,
                columnIndex,
                key: columnIndex,
                darkMode,
                displayMetricOnTrend,
                showUnitInSeparateColumn,
              });
          columnIndex++;
          return cell;
        }),
      );

      return <TableBuilderRow key={rowIndex}>{cells}</TableBuilderRow>;
    });

    if (!isLoading && _.isEmpty(tableData.capsules)) {
      rows.push(<TableBuilderNoResultsRow key={tableData.headers.length} colspan={columns.length} />);
    }

    return rows;
  };

  // Main table rows for transposed table. One row per capsule.
  const createTransposedTableRows = () => {
    if (!isLoading && _.isEmpty(tableData.capsules)) {
      const colspan = (showHeaders ? 1 : 0) + tableData.headers.length;
      return <TableBuilderNoResultsRow colspan={colspan} />;
    }

    return _.map(tableData.capsules, (capsule, rowIndex) => {
      let columnIndex = 0;
      const maybeStripedColor = getStripedColor(isStriped, rowIndex, darkMode);
      const cells: JSX.Element[] = [];
      // Cell for capsule start/end
      if (showHeaders) {
        cells.push(
          <TableBuilderDataCell
            textValue={formatHeader(headers, capsule.property, capsule.startTime, capsule.endTime, timezone)}
            columnIndex={rowIndex}
            style={computeCellColors(maybeStripedColor, darkMode)}
            extraClassNames="text-bolder text-center"
            key={columnIndex++}
            t={t}
          />,
        );
      }

      // Cells for each property or statistic for the capsule
      _.forEach(capsule.values, (value, valueIndex) => {
        const header = tableData.headers[valueIndex];
        if (isStartOrEndColumn(header)) {
          cells.push(
            renderConditionTimeCell({
              header,
              headers,
              capsule,
              maybeStripedColor,
              key: columnIndex,
              columnIndex: rowIndex,
              darkMode,
              timezone,
            }),
          );
        } else {
          cells.push(
            renderConditionDataCell({
              header,
              capsule,
              value,
              maybeStripedColor,
              key: columnIndex,
              columnIndex: rowIndex,
              darkMode,
              displayMetricOnTrend,
              showUnitInSeparateColumn,
            }),
          );
        }
        columnIndex++;
      });
      return <TableBuilderRow key={rowIndex}>{cells}</TableBuilderRow>;
    });
  };

  const renderTable = () => (
    <table
      data-testid="conditionTable"
      className="table table-bordered-first-column table-md-condensed fixedColumnTable width-auto screenshotSizeToContent">
      {showHeaders && <thead>{createHeaderRow()}</thead>}
      <tbody>{createTableRows()}</tbody>
    </table>
  );

  const renderTransposedTable = () => {
    const headers = createTransposedHeaderRows();
    return (
      <table
        data-testid="conditionTableTransposed"
        className="table table-bordered table-md-condensed fixedHeaderTable width-auto screenshotSizeToContent">
        {columns.length > 0 && <thead>{headers}</thead>}
        <tbody>{createTransposedTableRows()}</tbody>
      </table>
    );
  };

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      {isTransposed ? renderTransposedTable() : renderTable()}
    </DndProvider>
  );
};
